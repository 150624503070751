@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.MobileModalBox {
  @include screens-below('sm') {
    &.ModalClose {
      display: block;
    }

    &.ModalOpen {
      .Content {
        position: fixed;
        z-index: z('mobile-modal-box');
        top: -2.6rem;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        padding-bottom: 8rem;
        border: 0.1rem solid var(--color-border);
        margin-top: 2.6rem;
        background-color: var(--color-bg-2);

        > div {
          padding: 0 2rem;
        }
      }

      .ButtonGoBack {
        width: 100%;
        height: 4rem;
        border: 0;
        background-color: var(--grey-100);
      }
    }
  }

  @include screens-above('sm') {
    .ButtonGoBack {
      display: none;
    }
  }
}
