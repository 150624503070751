.LabelWrapper {
  display: inline-flex;
  padding: 0;
  vertical-align: bottom;
}

.Label {
  margin: 0.8rem 0.8rem 0.8rem 0;
  color: var(--grey-400);
}

.CoinWarning {
  color: var(--red-60);
  cursor: default;
}

.Checkbox {
  margin-top: 0.8rem;
}
