@import 'src/styles/mediaQueries';

.HasMediaContainer {
  @include screens-above('lg') {
    display: flex;
    max-height: 74rem;
  }

  height: auto;
}

$PostBodyPadding: 2.4rem;

.PostBody {
  padding: $PostBodyPadding;
  color: var(--color-text-title);
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.7rem;
  line-height: 2.6rem;
}

.HasMediaContainer .PostBody {
  @include screens-above('lg') {
    min-width: 40rem;
  }
}

.NoMediaContainer .PostBody {
  @include screens-above('lg') {
    overflow: hidden;
    max-height: calc(100vh - 40px);
  }
}

.ScrollSection {
  @include screens-above('lg') {
    overflow-y: auto;
  }
}

.HasMediaContainer .ScrollSection {
  @include screens-above('lg') {
    position: absolute;
    width: 35.2rem;
    height: 94%;
    padding-bottom: 2.5rem;
  }
}

.NoMediaContainer .ScrollSection {
  max-height: 40rem;
}

.PostBodyText {
  margin-bottom: 2.4rem;
}

.PostBodyTitle {
  margin-bottom: 1.6rem;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0.01rem;
  line-height: 3.2rem;
}

.AuthorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2rem;
}

.AuthorContainer :global(.sb-avatar) {
  margin-right: 1rem;
}

.MediaContainer {
  width: 100%;
}

@include screens-above('lg') {
  .PostBodyText {
    margin-bottom: 4rem;
  }
}
