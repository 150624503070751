@import 'src/styles/mediaQueries';

.Card {
  display: grid;
  width: 100%;
  min-height: 14.6rem;
  background-color: var(--grey-100);
  grid-template-columns: 1fr;

  @include screens-below('md') {
    display: block;
    padding: 1rem;
  }
}

.HoursRate {
  display: flex;
  justify-content: space-between;

  :first-child {
    padding-right: 2rem;
  }
}

.notEditable {
  grid-template-columns: 1fr 10rem;
}

.Container {
  padding: 1.6rem;

  @include screens-below('md') {
    padding: 1rem;
  }
}

.Heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  color: var(--grey-800);
}

.MainText {
  margin: 1.6rem 0 0 0;
  color: var(--grey-600);
}
