@import 'src/styles/mediaQueries';

.Legend {
  display: block;
  min-width: 18.7rem;
  padding: 0 1.7rem;
  margin-right: 2.5rem;
  margin-bottom: 2.1rem;
  color: var(--color-text-main);
}

.Legend_noPadding {
  padding: 0;
}

.Legend_submitStep {
  margin-bottom: 2rem;
  font: 700 2rem / 1.5 'Source Sans Pro', sans-serif;
}

@include screens-above('md') {
  .Legend_submitStep {
    margin-top: 5.1rem;
    margin-bottom: 4.5rem;
    font-size: 2.2rem;
  }
}
