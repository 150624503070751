@import 'src/styles/mediaQueries';

.Row {
  grid-column: 1 / 2;
}

.Label {
  padding: 0;
  margin-bottom: 0.8rem;
  color: var(--grey-400);
}

.DroppableContent {
  display: flex;
  width: 100%;
  height: 12rem;
  align-items: center;
  justify-content: center;

  @include screens-below('lg') {
    height: 24rem;
  }
}

.Files {
  display: inline-block;
}

.FileContainer {
  display: inline-flex;
  border: 0.1rem solid var(--grey-300);
  margin-right: 0.5rem;

  @include screens-below('lg') {
    margin: 0.5rem;
  }
}

.FileName {
  padding: 0.75rem 2rem;
  border-right: 0.1rem solid var(--grey-300);
  margin: 0;
  color: var(--grey-400);
}

.IconContainer {
  padding: 0.75rem 0.75rem 0 0.75rem;
  background-color: var(--grey-200);
}
