@import 'src/styles/mediaQueries';

.Row {
  display: flex;
  padding: 0 1.7rem;
}

.Row_noPadding {
  padding: 0;
}

.Row_padding {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.Row_hidden {
  display: none;
}

.Row_collapsable {
  flex-direction: row;
  flex-wrap: wrap;
}

.Row_reimbursementNotes,
.Row_reimbursement {
  flex-direction: column;
  padding: 0;
  margin-bottom: 2.7rem;
}

.Row_reimbursementUnleashCategory,
.Row_reimbursementReferral {
  padding: 0;
  margin-bottom: 1.7rem;
}

.Row_reimbursementNotes {
  display: flex;
}

.Row_horizontal {
  margin-bottom: 0;
}

.Row_reimburseHorizontal {
  width: 100%;
  justify-content: center;
  margin: 0 auto;
}

.Row_vertical {
  flex-direction: column;
}

.Row_reimburseVertical {
  width: 100%;
  min-width: 0.8rem;
  flex-direction: column;
}

.Row_withMargin {
  margin-right: 0.8rem;
}

.Row_withLeftMargin {
  margin-left: 0.5rem;
}

.Row_withMargin:last-of-type {
  margin-right: 0;
}

.Row_modal {
  flex-direction: column;
  padding: 0.8rem 0;
}

@include screens-above('md') {
  .Row_modal {
    flex-direction: row;
  }

  .Row_reimbursementNotes {
    height: 11.6rem;
  }

  .Row_horizontal {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .Row_reimburseHorizontal {
    max-width: 15rem;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 5.5rem 0 0.5rem;
  }

  .Row_reimburseVertical {
    min-width: unset;
  }
}
