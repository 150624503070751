@import 'src/styles/mediaQueries';

.Card {
  display: grid;
  width: 100%;
  min-height: 14.6rem;
  background-color: var(--grey-100);
  grid-template-columns: 5fr 1fr;

  @include screens-below('md') {
    grid-template-columns: 1fr;
  }
}

.Container {
  padding: 1.6rem;

  @include screens-below('md') {
    padding: 1rem;
  }
}

.Types {
  display: flex;
  padding: 0;
  margin: 0.8rem 0;
  list-style-type: none;

  & li:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.Type {
  background-color: var(--grey-400);
}

.TypeText {
  color: var(--white);
}

.Name {
  display: -webkit-box;
  overflow: hidden;
  max-width: calc(38.6rem - 3.2rem);
  margin: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
}

.Description {
  @extend .Name;

  color: var(--grey-500);
  -webkit-line-clamp: 2;
}

.File {
  display: inline-flex;
  height: 4rem;
  padding: 1rem 2.4rem;
  border: 0.1rem solid var(--grey-300);
  margin-right: 1rem;
  background-color: var(--white);
  color: var(--grey-400);

  @include screens-below('md') {
    margin: 0 1rem 1rem 0;
  }
}

.Ammounts {
  text-align: end;

  @include screens-below('md') {
    text-align: start;
  }
}

.ReferralContainer {
  display: inline-flex;
  margin-top: 0.5rem;
}

.ReferralName {
  padding-top: 0.6rem;
  margin: 0;
  margin-left: 1rem;
}

.MobileDivider {
  width: 90%;
  margin: auto;
}
