@mixin header-button {
  position: fixed;
  z-index: 99999;
  top: 0;
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.XhqDateRangePicker__mobile_controls__clearButton {
  @include header-button;

  left: 0;
  color: var(--red-60);

  &:hover {
    color: var(--red-80) !important;
  }
}

.XhqDateRangePicker__mobile_controls__closeButton {
  @include header-button;

  right: 0;
  width: 5rem;
  padding: 0;
}

.XhqDateRangePicker__mobile_controls__footer {
  position: fixed;
  z-index: 99999;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.7rem;
  border-top: 1px solid var(--grey-300);
  background: var(--color-bg-2);
}
