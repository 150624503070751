.light {
  /* Bg colors */
  --color-bg-2: #fff;
  --color-bg-inverted-2: #19191b;
  --color-bg-nav-active: #ffeaed;
  --color-light-gray: #e6e6e6;

  /* Primary colors */
  --red-20: #ffeaed;
  --red-40: #ff9ea5;
  --red-60: #ff5964;
  --red-80: #e02242;

  /* Featured */
  --featured-20: #ffedc9;
  --featured-40: #f5bc6c;
  --featured-60: #eb9b00;
  --featured-80: #e67c00;
  --featured-100: #a95b00;

  /* Rare */
  --rare-20: #c9edfc;
  --rare-40: #96d6f1;
  --rare-60: #2ba0d2;
  --rare-80: #3293bc;
  --rare-100: #0472a1;

  /* Legendary */
  --legendary-200: #ece0ff;
  --legendary-400: #bf9bf9;
  --legendary-600: #6a27d8;
  --legendary-800: #4c0db4;

  /* Shades of grey */
  --grey-100: #f9f9f9;
  --grey-200: #f3f3f3;
  --grey-300: #e5e5e5;
  --grey-400: #9a9d9e;
  --grey-500: #5a5665;
  --grey-600: #34323b;
  --grey-700: #262629;
  --grey-800: #19191b;

  /* Black and White */
  --white: #fff;
  --black: #000;
  --rgb-black: 0, 0, 0;

  /* Nav */
  --color-nav-icon: #5a5665;
  --color-text-nav-active: #ff5964;

  /* Utility */
  --utility-green-20: #eeffe8;
  --utility-green-40: #c5f4b4;
  --utility-green-80: #32bf00;
  --utility-green-100: #228100;
  --color-brand-main: #ff5964;
  --color-border: #e9edee;
}

.dark {
  /* Bg colors */
  --color-bg-2: #19191b;
  --color-bg-inverted-2: #fff;
  --color-bg-nav-active: #5a5665;
  --color-light-gray: #474747;

  /* Primary colors */
  --red-20: #e02242;
  --red-40: #ff5964;
  --red-60: #ff9ea5;
  --red-80: #ffeaed;

  /* Featured */
  --featured-20: #a95b00;
  --featured-40: #e67c00;
  --featured-60: #eb9b00;
  --featured-80: #f5bc6c;
  --featured-100: #ffedc9;

  /* Rare */
  --rare-20: #0472a1;
  --rare-40: #3293bc;
  --rare-60: #2ba0d2;
  --rare-80: #96d6f1;
  --rare-100: #c9edfc;

  /* Legendary */
  --legendary-200: #4c0db4;
  --legendary-400: #6a27d8;
  --legendary-600: #bf9bf9;
  --legendary-800: #ece0ff;

  /* Shades of grey */
  --grey-100: #262629;
  --grey-200: #34323b;
  --grey-300: #5a5665;
  --grey-400: #9a9d9e;
  --grey-500: #e5e5e5;
  --grey-600: #f3f3f3;
  --grey-700: #f9f9f9;
  --grey-800: #f9f9f9;

  /* Black and White */
  --white: #19191b;
  --black: #f9f9f9;
  --rgb-black: 0, 0, 0;

  /* Nav */
  --color-nav-icon: #f3f3f3;
  --color-text-nav-active: #fff;

  /* Utility */
  --utility-green-20: #eeffe8;
  --utility-green-40: #c5f4b4;
  --utility-green-80: #32bf00;
  --utility-green-100: #228100;
  --color-brand-main: #ff5964;
  --color-border: #5a5665;
}
