.Textarea {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1.2em 1em;
  border: 1px solid var(--grey-300);
  appearance: none;
  background-color: var(--white);
  border-radius: 0;
  color: var(--grey-800);
}

.Label {
  margin-bottom: 0.8rem;
  color: var(--grey-400);
}

.Required {
  margin-left: 0.4rem;
  color: var(--red-60);
  font-weight: bold;
}
