@import 'src/styles/mediaQueries';

.Logo {
  @include screens-below('lg') {
    position: absolute;
    top: 1.8rem;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}
