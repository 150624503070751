.CommonLabelLayout {
  width: fit-content;
  padding: 0 4px;

  &.LabelClickable:focus {
    outline: 1px dashed var(--red-60);
  }
}

.LabelCapitalize {
  text-transform: capitalize;
}

.LabelText {
  margin: 0;
  color: var(--white);
}

.LabelDarkText {
  composes: LabelText;
  color: var(--black);
}

.LabelErrorText {
  composes: LabelText;
  color: var(--red-80);
}

.LabelErrorMessage {
  composes: LabelText;
  color: var(--red-60);
  font-weight: 400;
}

.LabelFeaturedText {
  composes: LabelText;
  color: var(--featured-100);
}

.LabelSuccessText {
  composes: LabelText;
  color: var(--utility-green-80);
}

.LabelClickable {
  cursor: pointer;
}

.LabelNoBackground {
  composes: CommonLabelLayout;
  padding: 0 0;
  background-color: transparent !important;
}

.LabelCommon {
  composes: CommonLabelLayout;
  background-color: var(--grey-400);
}

.LabelBlack {
  composes: CommonLabelLayout;
  background-color: var(--black);
}

.LabelError {
  composes: CommonLabelLayout;
  background-color: var(--red-20);
}

.LabelPrimary {
  composes: CommonLabelLayout;
  background-color: var(--red-60);
}

.LabelFeatured {
  composes: CommonLabelLayout;
  background-color: var(--featured-20);
}

.LabelSuccess {
  composes: CommonLabelLayout;
  background-color: var(--utility-green-20);
}

.LabelBig {
  padding: 0.6rem 1.2rem;
}

.LabelFullWidth {
  width: 100%;
  text-align: center;
}
