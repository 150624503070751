.Horizontal {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--grey-300);
  margin: 0.5rem 0;
}

.Vertical {
  width: fit-content;
  height: 100%;
  min-height: 3rem;
  align-self: center;
  border-right: 1px solid var(--grey-300);
}
