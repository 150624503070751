@import 'src/styles/mediaQueries';

.TableContainer {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.Table {
  width: 100%;
  background-color: var(--white);
  border-spacing: 0;

  tr {
    th {
      text-align: left;
    }
  }
}

.TableAdmin {
  tr {
    th {
      padding: 2.6rem 0 2.6rem 2.4rem;
      border-bottom: 0.06rem solid var(--grey-300);
    }

    td {
      padding: 2.6rem 0 2.6rem 2.4rem;
      border-bottom: 0.06rem solid var(--grey-200);
    }

    th:last-child {
      padding: 2.6rem 2.4rem;
    }

    td:last-child {
      padding: 2.6rem 2.4rem;
    }

    @include screens-below('md') {
      th,
      td {
        padding: 1.2rem 0.8rem;
      }
    }
  }
}
