@import 'src/styles/mediaQueries';

.SummaryRow {
  display: none;
}

.plusIcon {
  display: inline-block;
  padding-left: 1.9rem;
  background-color: var(--grey-400);
  -webkit-mask-image: url(../../assets/a-plus-button.svg);
  mask-image: url(../../assets/a-plus-button.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 14px 14px;
  mask-size: 14px 14px;
}

.ReimHeader {
  display: none;
}

.UnleashBudgetError {
  width: 100%;
  padding: 0.7rem 0;
  color: var(--color-red);
  font-weight: bold;
}

@include screens-above('md') {
  .SummaryRow {
    display: flex;
    width: 100%;
    justify-content: left;
    margin-bottom: 1.7rem;
    color: var(--color-text-main);
    font: 1.4rem / 1.3 'Source Sans Pro', sans-serif;
  }

  .SummaryValue,
  .SummaryCalculation,
  .SummaryLabel,
  .SummaryNotes {
    flex: 1;
  }

  .SummaryCalculationTotal {
    flex: 1;
  }

  .SummaryCalculationTotal,
  .SummaryLabel {
    color: var(--color-text-gray);
  }

  .ReimHeader {
    display: flex;
  }
}
