@import 'src/styles/mediaQueries';

.MoodPicker {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;

  @include screens-below('lg') {
    justify-content: space-between;
  }

  @include screens-below('md') {
    flex-wrap: wrap;
  }
}
