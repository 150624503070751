@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.InvoiceTotal {
  padding: 1.6rem;
  border: 1px solid var(--color-brand-main);
  margin: 2.4rem auto;
  background-color: var(--color-bg-2);
  border-radius: 0.4rem;
  color: var(--color-brand-main);
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}

@include screens-above('xl') {
  .InvoiceTotal {
    position: fixed;
    z-index: z('popover');
    top: 50%;
    right: 2rem;
    max-width: none;
    margin: 0;
    transform: translateY(-50%);
  }
}
