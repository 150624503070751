.Container {
  width: 100%;
  height: 40px;
}

.Right {
  float: right;
}

.FocusReset {
  &:focus {
    outline: unset;
  }
}
