@import 'src/styles/mediaQueries';

.NewCompanyModal {
  max-width: 526px;
  max-height: 100vh;
  padding: 0 !important;

  > div {
    padding: 2.5rem;
  }
}

.NewCompanyModalHeader {
  margin: 0 0 1.6rem;
  color: var(--black);
  line-height: 2.5rem;
}

.InputLabel {
  line-height: 2rem;
}

.InputRequiredLabel {
  margin-left: 0.4rem;
  line-height: 2rem;
}

.FormItem {
  margin-bottom: 0.8rem;
}

.FormInput {
  margin: 0.8rem 0 0 0;
}

.NewCompanyModalButtonWrapper {
  display: flex;
  justify-content: flex-end;

  button {
    flex: 0 0 100%;
  }
}

@include screens-above('md') {
  .NewCompanyModal {
    padding: 4rem;
  }

  .NewCompanyModalHeader {
    margin: 0 0 2.4rem;
    line-height: 3.2rem;
  }

  .NewCompanyModalButtonWrapper {
    button {
      flex: 0 0 50%;
    }
  }

  .FormItem {
    margin-bottom: 1.6rem;
  }
}
