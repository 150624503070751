@import 'src/styles/mediaQueries';

@include screens-above('md') {
  .Textarea_wrap {
    width: 19.6rem;
  }

  .isPayment {
    width: 100%;
    max-width: 65.5rem;
  }
}

.Textarea_big {
  width: 42.5rem;
  height: 25.5rem;
}

.Textarea {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1.2em 1em;
  border: 1px solid var(--color-border);
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-bg-2);
  border-radius: 0;
  color: var(--color-text-main);
}

.Textarea_reimburseNotes,
.Textarea_submitStep {
  width: 100%;
  height: 100%;
  padding: 1.2em 1em;
  border: 1px solid var(--color-border);
  color: var(--color-text-main);
  font: 1.4rem / 1.6 'Source Sans Pro', sans-serif;
}

.noPadding {
  padding: 0;
}

.isPayment {
  width: 100%;
}

.Textarea_wrap.Textarea_fullwidth {
  width: 100%;
  margin-bottom: 2.7rem;
}

.Textarea_modal textarea {
  width: 100%;
  padding: 0 0.8rem;
  margin: 0;
  color: var(--color-text-main);
  font: 100 1.7rem / 1.6 'Source Sans Pro', sans-serif;
}

.Textarea:focus {
  border: 1px solid var(--color-brand-main);
  box-shadow: 0 0 1px var(--color-text-title);
  outline: none !important;
}

@include screens-above('md') {
  .Textarea_wrap {
    width: 19.6rem;
  }

  .Textarea_modal {
    width: 29.8rem;
  }

  .Textarea_reimburseNotes,
  .Textarea_submitStep,
  .isPayment {
    width: 100%;
    max-width: 65.5rem;
  }

  .Textarea_reimburseNotes,
  .Textarea_submitStep {
    height: 11.6rem;
    margin-bottom: 4.3rem;
  }

  .Textarea_reimburseNotes {
    width: 65.5rem;
  }

  .Textarea_reimburseNotes textarea {
    width: 100%;
    height: 11.6rem;
    padding: 1.7rem;
    border: 1px solid var(--color-border);
    color: var(--color-text-main);
    resize: none;
  }

  .Textarea_submitStep {
    resize: none;
  }
}
