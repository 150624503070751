.Accordion {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem 0.3rem;
  background: var(--grey-100);
  row-gap: 1rem;
}

.Content {
  display: flex;
}

.HeaderContainer {
  display: flex;
  width: 100%;

  :focus {
    outline-color: transparent;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  flex-grow: 10;
  justify-content: space-between;

  & p {
    margin: 0;
  }
}

.Icon {
  display: flex;
  flex-grow: 0.4;
  align-self: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }
}

.LeftText {
  align-self: center;
  justify-content: center;
  padding-left: 0.71rem;
  color: var(--grey-500);
}

.RightText {
  align-self: center;
  justify-content: center;
  color: var(--grey-400);
}

@media screen and (max-width: 576px) {
  .Accordion {
    row-gap: 0;
  }

  .RightText {
    display: none !important;
  }
}
