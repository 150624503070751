@mixin font {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.25rem;
}

.FormLabel {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.FormLabelLayoutAuto {
  width: auto;
}

.FormLabelLayoutFill {
  width: 100%;
}

.FormLabelHeader {
  @include font;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}

.FormLabelTitle {
  color: var(--grey-400);
  font-weight: 700;
}

.FormLabelRequired {
  color: var(--red-60);
  font-weight: 700;
}

.FormLabelHelpText {
  @include font;

  margin: 0;
  color: var(--grey-500);
}
