@import 'src/styles/mediaQueries';

.NavContainer {
  overflow: scroll;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.NavContainer ::-webkit-scrollbar {
  display: none;
}

.NavContainer li > a,
.NavContainer li > a:focus,
.NavContainer li > a:active {
  outline: none;
}

.NavItem {
  display: inline-block;
  border-bottom: 1px solid var(--color-border);
  list-style: none;

  & > a {
    flex: 0 0 auto;
    padding: 0 1rem 1rem;
    margin: 0;
    color: var(--grey-400);
    font-weight: 700;
    letter-spacing: 0.02rem;
    list-style: none;
  }

  & > .NavActive {
    border-bottom: 0.2rem solid var(--color-brand-main);
    color: var(--grey-700);
  }
}

@include screens-above('md') {
  .NavContainer {
    overflow: auto;
    padding: 0 2.5rem;
  }

  .NavItem > a {
    padding: 0 1.5rem 1rem;
  }
}
