@import 'src/styles/zIndex';
@import 'src/styles/mediaQueries';

.ArrowContainer {
  position: absolute;
  z-index: z('base');
  top: calc(50% - 2rem);
  display: flex;
  width: 4rem;
  height: 4rem;
  backdrop-filter: blur(24px);
  background: rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

.LeftArrowContainer {
  left: 0.8rem;
}

.RightArrowContainer {
  right: 0.8rem;
}

.ArrowIcon {
  height: 1.4rem;
  margin: auto;
  fill: #fff;
}

.ArrowIconLeft {
  transform: rotate(180deg);
}

.MultimediaViewerWrapper {
  & * {
    box-sizing: border-box;
  }
}

.HasMediaContainer {
  @include screens-above('lg') {
    display: flex;
    max-height: 74rem;
  }
}

.MediaCarouselContainer {
  width: 100%;
  min-height: 25rem;

  @include screens-below('md') {
    max-height: 40rem;
  }

  background-color: var(--color-bg-2);
}

.Media {
  width: 100%;
  height: 100%;
}

.Video {
  max-height: 100%;
  object-fit: fill;
}

.VideoContainer {
  display: flex;
  height: 100%;
  align-items: center;
  background-color: var(--color-bg-2);
}

.Carousel,
.Carousel :global(.carousel),
.Carousel :global(.slider-wrapper),
.Carousel :global(.slider),
.Carousel :global(.slide) {
  height: auto;
}

.Carousel :global(.slide) {
  display: flex;
  justify-content: center;
  background-color: white !important;
}

.Carousel :global(.carousel + .carousel) {
  display: none;
}

.Carousel {
  * li {
    outline: none;
  }
}

.Carousel :global(.carousel .control-dots) {
  position: absolute;
  bottom: -14px;
  left: 50%;
  width: auto;
  transform: translate(-50%, -50%);
}

.Carousel :global(.carousel .slide img) {
  width: auto;
  max-width: 100%;
  object-fit: cover;
}
