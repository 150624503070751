.PlusIcon {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.9rem;
  background-color: var(--grey-400);
  -webkit-mask-image: url(../../../assets/white-plus-icon.svg);
  mask-image: url(../../../assets/white-plus-icon.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 1.4rem 1.4rem;
  mask-size: 1.4rem 1.4rem;
}

.ButtonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.InputContainer {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  gap: 0.4rem;
}

.InputButton {
  width: 4rem;
  min-width: 4rem;
  padding: 0 1.1rem;
}
