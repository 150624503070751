.Entry {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  > p {
    padding: 0;
    margin: 0;
  }
}

.Title {
  color: var(--grey-400);
}
