@import 'src/styles/zIndex';

.Avatar {
  position: relative;
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.RedVariant {
    background-color: var(--red-60);

    .AvatarInitials {
      color: var(--white);
    }
  }

  &.GreyVariant {
    background-color: var(--grey-200);

    .AvatarInitials {
      color: var(--grey-800);
    }
  }
}

.AvatarClickable {
  cursor: pointer;

  &:active {
    outline: 1px dashed var(--red-60);
  }

  &:focus {
    outline: 1px dashed var(--red-60);
  }
}

.AvatarInitials {
  margin: 0;
  border-radius: 50%;
  text-transform: uppercase;
}

.AvatarImageSource {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.AvatarWithName {
  display: flex;
  align-items: center;
}

.UserAvatar {
  margin-right: 0.8rem;
}
