@import 'src/styles/mediaQueries';

.Container {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include screens-below('lg') {
    flex-direction: column-reverse;
    gap: 0.8rem;
  }
}

.FocusReset {
  &:focus {
    outline: unset;
  }
}
