@import 'src/styles/mediaQueries';

.BountiesCollectionTab {
  width: 32rem;
  cursor: pointer;

  &.Checked {
    @include screens-above('md') {
      border: 1px var(--grey-800) solid;
    }
  }

  &.Locked {
    p.Title {
      color: var(--grey-400);
    }

    p.ClaimNumbers {
      color: var(--grey-300);
    }
  }

  .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0 1rem;
    }
  }

  .Body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;

    .Badge {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .Lock {
      display: flex;
      align-items: center;
    }

    .Lock svg {
      width: 2rem;
      height: 2.5rem;
    }

    .Lock svg path {
      fill: var(--grey-400);
    }
  }
}

.Title {
  color: var(--grey-600);
  font-size: 1.5rem;
  font-weight: 700;
}

.ClaimNumbers {
  color: var(--grey-400);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: x-large;
  font-style: italic;
  font-weight: 800;
}

.CollectionIcon {
  height: 3rem;
  margin-right: 1.5rem;
}
