@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.Overlay {
  position: fixed;
  z-index: z('modal', 'lvl-2');
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0;
  background-color: rgba(var(--rgb-black), 0.8);

  > * {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

.Close {
  display: none;
}

.Modal {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 8rem);
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: auto;
  background-color: var(--color-bg-2);
}

.GoBack {
  display: flex;
  width: 100%;
  min-height: 4rem;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  border: none;
  background-color: var(--grey-100);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;

  & span {
    margin-left: 0.8rem;
  }

  &:focus {
    border: none;
    outline: 0.1rem solid var(--red-60);
    outline-style: auto;
  }
}

.Title {
  padding: 1.3rem 1.6rem;
  margin: 0;
  color: var(--black);
  font-size: 2.2rem;
  line-height: 1.125;
}

.Content {
  overflow: auto;
  padding: 1.6rem;
}

.Footer {
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  margin-top: 1.6rem;
  gap: 0.8rem;
}

.PrimaryButton,
.SecondaryButton {
  width: 100%;
  height: 4rem;
  max-height: 4rem;
  flex: 1;
  padding: 0;
}

@include screens-above('md') {
  .Overlay {
    > * {
      margin: auto;
    }
  }

  .Close {
    position: absolute;
    z-index: 2;
    top: 2.4rem;
    right: 2.4rem;
    display: flex;
    width: 3.2rem;
    height: 3.2rem;
  }

  .Modal {
    position: relative;
    z-index: 1;
    display: flex;
    min-width: 33%;
    min-height: initial;
    padding: 1.6rem;
  }

  .LayoutCenter {
    padding: 1.4rem 0;

    .Close {
      > svg > path {
        fill: var(--white);
      }
    }

    .Title {
      padding-right: 0;
      padding-left: 0;
    }

    .Modal {
      width: 68.4rem;
      height: fit-content;
    }
  }

  .LayoutRight {
    > * {
      margin-right: 0;
    }

    .Modal {
      width: 44.8rem;
      height: 100vh;
    }

    .Title {
      padding-right: 4rem;
      padding-left: 0;
    }

    .Content {
      flex-grow: 1;
    }
  }

  .GoBack {
    display: none;
  }

  .Content {
    width: 100%;
    padding: 0;
  }

  .Footer {
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
  }

  .PrimaryButton {
    width: 10rem;
    max-width: 10rem;
  }

  .SecondaryButton {
    width: 9.1rem;
    max-width: 9.1rem;
  }
}
