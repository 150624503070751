.spacing {
  display: flex;
}

.spacing--small {
  gap: 0.8rem;
}

.spacing--medium {
  gap: 1.6rem;
}

.spacing--large {
  gap: 2.4rem;
}

.spacing--row {
  flex-direction: row;
}

.spacing--column {
  flex-direction: column;
}

.spacing--start {
  align-items: flex-start;
}

.spacing--center {
  align-items: center;
}

.spacing--end {
  align-items: flex-end;
}

.spacing--baseline {
  align-items: baseline;
}
