.PencilButton {
  min-width: 0rem;
  background-color: transparent;
  border: 0 none;
  outline: none;
}

.PencilIcon {
  background-color: var(--grey-400);
  -webkit-mask-image: url(../../../assets/icons/pencil.svg);
  mask-image: url(../../../assets/icons/pencil.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 75%;
  mask-size: 75%;
  display: inline-block;
  padding-right: 2rem;
  min-height: 2rem;
  margin: 0rem 0rem 0 1rem;
  cursor: pointer;
}
