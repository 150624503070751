.ModalTitle {
    display: flex;
    margin: 0;
    color: var(--grey-800);
  }
  
  .popupItem {
    display: flex;
    margin: 1.6rem 0;
  }

  .inputStyle {
    width: 100%;
    margin: 0;
  
    & div {
      margin: 0;
  
      & input {
        min-width: 0;
        border: 1px solid var(--grey-300) !important;
      }
    }
  }

  .secondaryButton {
    display: none;
  }