@import 'src/styles/mediaQueries';

.HomeVideoSection {
  width: 100%;
  margin-bottom: 2.8rem;
}

.HomeVideo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 3.15rem 2.4rem;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}

.HomeTitle {
  margin: 0;
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 2.5rem;
}

.ModalBtn {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  text-transform: initial;
}
