@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

@keyframes fade-in-up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Hide {
  display: none;
}

.VideoEmbed {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */

  @include screens-below('lg') {
    padding-bottom: 75%; /* 4:3 */
  }
}

.VideoEmbed.PopupPlayer {
  position: fixed;
  top: unset;
  right: 20px;
  bottom: 20px;
  width: 260px;
  height: 145px;
  padding-bottom: 0;
  animation: fade-in-up 0.25s ease forwards;
  transform: translateY(100%);
}

.ClosePopupButton {
  position: absolute;
  top: -2.5rem;
  right: 0;
  display: block;
  width: 24px;
}

.VideoEmbed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
