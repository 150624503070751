@import 'src/styles/mediaQueries';

.Card {
  display: grid;
  width: 100%;
  min-height: 14.6rem;
  background-color: var(--grey-100);
  grid-template-columns: 1fr 16rem;

  @include screens-below('md') {
    display: block;
    padding: 1rem;
  }
}

.notEditable {
  grid-template-columns: 1fr 9rem;
}

.Container {
  padding: 1.6rem;

  @include screens-below('md') {
    padding: 1rem;
  }
}

.Types {
  display: flex;
  padding: 0;
  margin: 0.8rem 0;
  list-style-type: none;

  & li:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.Type {
  background-color: var(--grey-400);
}

.TypeText {
  color: var(--white);
}

.Name {
  display: -webkit-box;
  overflow: hidden;
  max-width: calc(38.6rem - 3.2rem);
  margin: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
}

.Status {
  display: -webkit-box;
  overflow: hidden;
  max-width: calc(38.6rem - 2.2rem);
  margin-top: 0.3rem;
  -webkit-box-orient: vertical;
  font-size: 1.4rem;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
}

.Approved {
  color: #0472a1;
}

.Paid {
  color: #228100;
}

.Submitted {
  color: #a95b00;
}

.Description {
  @extend .Name;

  color: var(--grey-500);
  -webkit-line-clamp: 2;
}

.FileContainer {
  margin-bottom: 1rem;
  background: white;
}

.DropSection {
  display: none;
}

.RightContainer {
  display: inline-flex;
  padding: 0.8rem;

  @include screens-below('md') {
    display: block;
  }
}

.AmountsContainer {
  flex-direction: column;

  @include screens-below('md') {
    display: block;
  }
}

.AmountsTotal {
  padding-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: start;

  @include screens-below('md') {
    padding-left: 0;
  }
}

.AmountsDetail {
  margin: 0;
  text-align: start;

  @include screens-below('md') {
    padding-left: 1rem;
  }
}

.Edit {
  margin-right: 1.5rem;
}

.ActionsContainer {
  display: inline-flex;
  padding-top: 2rem;
  padding-right: 1rem;

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }

  @include screens-below('md') {
    padding-top: 1rem;
  }
}

.ActionsContainerDarkTheme {
  & svg {
    & path {
      fill: var(--grey-500);
    }
  }
}

.ReferralContainer {
  display: inline-flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ReferralName {
  padding-top: 0.6rem;
  margin: 0;
  margin-left: 1rem;
}

.MobileDivider {
  width: 90%;
  margin: auto;
}

.ModalTitle {
  margin: 1rem;
}

.ModalDescription {
  padding-left: 1rem;
}

.ModalFooter {
  padding: 1rem;
}
