.Comment {
  display: flex;
  flex-direction: column;
}

.CommentContent {
  padding: 1rem;
}

.CommentUserName {
  color: var(--color-bg-inverted-2);
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}

.CommentDate {
  color: var(--grey-400);
  font-size: 1.4rem;
  font-weight: 400;
}

.User {
  display: flex;
  flex-direction: column;

  & > p {
    margin: 0;
  }
}

.HideElement {
  visibility: hidden;
  height: 0;
}

.Footer {
  display: flex;
  align-items: center;
  visibility: visible;
  height: auto;

  * span,
  button {
    padding-left: 1rem;
    color: var(--grey-400);

    & svg {
      & path {
        fill: var(--grey-400);
      }
    }
  }
}

.TeamIconStyle {
  position: relative;
  top: 0.2rem;
  width: 1.6rem;
  max-width: 100%;
  height: 1.6rem;
  margin: 0 0.4rem 0 0.4rem;
}
