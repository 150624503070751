@import 'src/styles/mediaQueries';

.Column {
  flex-basis: 100%;
}

.dateRangeWrap {
  padding-top: 1.2rem;
}

.EditLabel {
  width: 100%;
  padding: 1.2rem 0 0 0;
  margin: 0;
  font: 1.4rem / 2.2 'Source Sans Pro', sans-serif;
}

.SummaryRow {
  composes: SummaryRow from './Shared.module.scss';
}

.SummaryLabel {
  composes: SummaryLabel from './Shared.module.scss';
}

.SummaryValue {
  composes: SummaryValue from './Shared.module.scss';
}

.Value {
  display: block;
  width: 19.6rem;
  padding: 0 1.7rem;
  margin-right: 2.5rem;
  margin-bottom: 1.7rem;
  color: var(--color-text-main);
  font-size: 1.4rem;
  font-weight: bold;
}

.ValueInline {
  display: inline;
}

@include screens-above('md') {
  .Column {
    flex: 1;
  }
}
