@import 'src/styles/mediaQueries';

.Wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.Separator {
  width: 100%;
  height: 1px;
  margin-bottom: 30px;
  background-color: var(--red-60);

  &.Inactive {
    background-color: var(--grey-300);
  }

  @include screens-below('md') {
    margin-bottom: 0;
  }
}

.ItemWrapper {
  width: 50%;
}

@include screens-below('lg') {
  .Separator {
    width: 30%;
  }
}

@include screens-below('md') {
  .Separator {
    width: 20%;
  }
}
