@import 'src/styles/mediaQueries';

.InnerPage {
  background-color: var(--grey-100);

  .Content {
    display: grid;
    max-width: 960px;
    padding-bottom: 24px;
    margin: auto;
    gap: 24px;
    grid-template-columns: repeat(8, 1fr);

    @include screens-below('md') {
      gap: 16px;
    }
  }

  .PageTitle {
    grid-column: 1/-1;

    & > div > div {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .MainContent {
    display: grid;
    grid-column: 1/7;
    grid-row-gap: 24px;

    @include screens-below('md') {
      grid-row-gap: 8px;
    }
  }

  .MainContentOnly {
    grid-column: 1/-1;
  }

  .SideContent {
    grid-column: 7/9;
  }

  .SideContentSticky {
    position: sticky;
    top: 1rem;
    align-self: start;
  }

  @include screens-below('lg') {
    .Content {
      max-width: 90%;
      grid-template-columns: 1fr;
    }
  }

  @include screens-below('xl') {
    .MainContent {
      grid-column: 1/-1;
    }

    .SideContent {
      grid-column: 1/-1;
    }
  }

  @include screens-below('lg') {
    .Content {
      max-width: 90%;
      grid-template-columns: 1fr;
    }
  }
}
