@import 'src/styles/mediaQueries';

.FlexCenter {
  display: flex;
  align-items: center;
}

.Padded {
  padding: 2rem 2rem;
}

.Info {
  margin-left: 1.5rem;
}

.Name {
  display: block;
  margin-bottom: 0.8rem;
  color: var(--grey-800);
  font-size: 1.4rem;
  letter-spacing: 0.02rem;
}

.Email {
  letter-spacing: 0.02rem;
}

.Avatar {
  width: 100%;
  max-width: 8rem;
  height: 100%;
  max-height: 8rem;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

@include screens-above('md') {
  .AvatarLarge {
    max-width: 8rem;
    max-height: 8rem;
    margin: 0;
  }

  .Padded {
    padding: 4rem 7rem;
  }

  .Info {
    margin-left: 1.6rem;
  }

  .Email {
    margin: 0;
  }
}
