@import 'src/styles/mediaQueries';

.ModalContainer {
  display: grid;
  max-width: 56.1rem;
  flex-direction: column;
  padding: 1.4rem;
  border: 0.1rem solid var(--grey-300);
  grid-template-columns: 5fr;
}

.SelectLabel{
  margin-bottom: 0.8rem;
}

.InputContainer{
  margin: 0.8rem 0;
}

.UnleashCategoryLabel{
  margin-bottom:0.8rem;
}

.SaveButton{
  @include screens-above('md') {
    max-width: 22.3rem;
  }
}

.Heading {
  margin: 0;
}

.ButtonContainer{
  display: flex;
  justify-content: flex-end;
  margin-top: 1.4rem;
  margin-bottom: 1rem;
}

.Container {
  display: grid;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  padding: 1.5rem;
  border: 0.1rem solid var(--grey-300);
  grid-template-columns: 5fr 0.125fr;
}

.Row {
  grid-column: 1 / 2;
}

.FlexRow {
  @extend .Row;

  display: inline-flex;
}

.LabelHelper{
  margin-bottom: 0.6rem;
}

.DoubleRow {
  @extend .Row;

  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @include screens-below('md') {
    width: 50%;
    grid-template-columns: 1fr;
  }

  @include screens-below('sm') {
    width: 100%;
  }
}

.MobileDoubleRow {
  @include screens-below('md') {
    width: unset;
    grid-template-columns: 1fr 1fr;
  }

  & input {
    min-width: unset;
  }
}

.MobileDeleteContainer {
  display: inline-flex;
  margin: 1rem auto 0.25rem auto;
}

.MobileDeleteText {
  padding: 0.75rem 0;
  margin: 0;
  color: var(--red-60);
}

.MobileDeleteIconContainer {
  padding: 0.75rem 0.75rem 0 0.75rem;

  & svg {
    & path {
      fill: var(--red-60);
    }
  }
}

.Avatar {
  left: 0.8rem;
  width: 2.4rem;
  height: 2.4rem;
}

.DropSectionDarkTheme {
  & svg {
    & path {
      fill: var(--grey-500);
    }
  }
}
