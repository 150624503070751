@import 'src/styles/mediaQueries';

.Columns {
  display: grid;
  gap: 0.8rem;
  grid-template-columns: 1fr;

  @include screens-above('lg') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.FullRow {
  grid-column: 1/-1;
}
