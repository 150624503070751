@import 'src/styles/mediaQueries';

.Card {
  display: grid;
  width: 100%;
  gap: 0.8rem;
}

.SingleColumnCard {
  @extend .Card;

  grid-template-areas:
    'main'
    'sidebar'
    'footer';
  grid-template-columns: 1fr;
}

.DoubleColumnCard {
  @extend .Card;

  grid-template-areas:
    'main sidebar'
    'footer footer';
  grid-template-columns: 1fr 1fr;

  @include screens-below('lg') {
    grid-template-areas:
      'main'
      'sidebar'
      'footer';
    grid-template-columns: 1fr;
  }
}

.resetMargin {
  padding: 2.4rem;
  gap: 1.2rem;

  > * {
    padding: 0;
    margin: 0;
  }

  @include screens-below('md') {
    padding: 1.6rem;

    > * {
      padding: 0;
      margin: 0;
    }
  }
}

.Step {
  color: var(--grey-400);
}

.Title {
  color: var(--grey-600);
}

.TitleInactive {
  color: var(--grey-400);
}

.Subtitle {
  color: var(--grey-600);
}

.Description {
  color: var(--grey-400);
}

.Main {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  grid-area: main;
}

.Container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.Sidebar {
  grid-area: sidebar;
}

.Footer {
  grid-area: footer;
}
