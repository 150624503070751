@import 'src/styles/mediaQueries';

.Grid {
  display: grid;
  margin-top: 16px;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @include screens-below('md') {
    margin-top: 10px;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }
}

.ShortHeight {
  min-width: 211px;
  padding: 0 1.5rem 0 1.5rem;

  @include screens-below('md') {
    min-width: 100%;
  }
}

.RedBackground {
  background-color: var(--red-20);
}
