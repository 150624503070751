.BountyCardContainer {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 47.5rem;
  margin: auto;
  filter: grayscale(0);
}

.BountyCardContainer_NoFooter {
  min-height: 31.7rem;
}

.ImageContainer {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: auto;
}

.Image {
  position: relative;
  display: block;
  height: 12.55rem;
  margin: auto;
  object-fit: contain;
}

.Image_Done {
  filter: grayscale(100%);
  opacity: 0.5;
}

.BountyCardContent {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 41.2rem;
  border: 1px solid var(--color-gray);
  background: var(--color-bg-2);
}

.BountyCardContent_NoFooter {
  height: 25.4rem;
}

.BountyCardContent.BountyCardContent_Favorite {
  border-color: var(--color-brand-main);
}

.Coins {
  position: absolute;
  z-index: 2;
  padding: 1.6rem 0 0 1.6rem;
  color: var(--color-text-gray);
}

.CoinAnimation {
  position: relative;
  top: 0.2rem;
  margin-right: 0.4rem;
}

.BountyCardType {
  position: absolute;
  z-index: 2;
  top: -2rem;
  right: -0.1rem;
  width: 5.5rem;
  height: 2rem;
  background: var(--color-private-bg);
}

.BountyCardTypeText {
  color: var(--color-white);
  text-align: center;
}

.Eye {
  position: absolute;
  z-index: 2;
  top: 1.6rem;
  right: 1.8rem;
  width: 2rem;
  height: 1.4rem;
}

.Eye svg path {
  fill: var(--color-text-gray);
}

.Eye.Eye_hidden svg path {
  fill: var(--color-main-hover);
}

.noHoverHiddenIcon {
  pointer-events: none;
}

.noHoverFavoriteIcon {
  pointer-events: none;
}

.BountyTitleDescriptionContainer {
  flex: 1;
  margin: 7.6rem 1.6rem 0 1.6rem;
}

.Title {
  margin-bottom: 0.4rem;
  color: var(--color-text-title);
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  text-align: center;
}

.Tag {
  display: block;
  margin-bottom: 0.4rem;
  color: var(--color-text-gray);
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  text-align: center;
}

.MadeByUser {
  display: inline-block;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 700;
}

.RedTag {
  display: inline-block;
  color: var(--color-brand-main);
}

.Text {
  display: block;
  margin-bottom: 0.4rem;
  color: var(--color-text-main);
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  overflow-wrap: break-word;
  text-align: center;
}

.BountyFoot {
  position: absolute;
  bottom: 0;
  display: grid;
  width: 100%;
  height: 10.5rem;
  padding: 0 1.6rem 1.6rem 1.6rem;
  grid-gap: 0.4rem 0;
  grid-template-columns: 1fr;
}

.BountyFootButtonsContainer {
  display: flex;
  height: 40px;
  flex-direction: row;
}

.AddToListButton {
  margin-right: 5px;
}

.IconButton {
  display: flex;
  width: fit-content;
  height: 100%;
  padding-top: 0.8rem;
  margin: auto;

  > div {
    color: var(--color-text-gray);
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.04rem;
  }
}

.weeklyLevelWrapper {
  position: absolute;
  bottom: 14rem;
  width: 100%;
  text-align: center;

  p {
    padding: 0.5rem 0;
    font-weight: 600;
  }
}

.normal {
  background-color: #f9f3d3;
  color: rgb(136, 136, 24);
}

.easy {
  background-color: rgb(210, 247, 210);
  color: green;
}

.IconButton div:first-of-type {
  margin-right: 0.8rem;
}
