.Container {
  display: flex;
  width: 100%;
  height: 4rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.6rem 1rem 1rem;
  background-color: var(--grey-100);

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }
}
