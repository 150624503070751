.MenuItem {
  display: flex;
  height: 6.4rem;
  align-items: center;
  padding: 1.6rem;
  background-color: var(--white);
  cursor: pointer;

  &:hover,
  &:active {
    background-color: var(--color-bg-nav-active);

    & p {
      color: var(--color-text-nav-active);
    }
  }

  & *[stroke='#5A5665'],
  & *[stroke='#CECCD1'] {
    stroke: var(--color-nav-icon);
  }

  & *[fill='#5A5665'],
  & *[fill='#000'] {
    fill: var(--color-nav-icon);
  }
}

.Icon {
  width: 3.2rem;
  height: 3.2rem;
}

.SubItem {
  height: 4rem;
  padding: 0.8rem 2.4rem;
  background-color: var(--grey-100);

  & svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.UserMenu {
  height: 4rem;
  background-color: transparent;

  & svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.SubItems {
  padding: 0;
  margin: 0;
}

.Link {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
