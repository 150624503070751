@mixin control {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-200);
  gap: 0.8rem;

  & > button {
    width: 4rem;
    height: 4rem;
  }
}

.RightSideControl {
  @include control;

  padding: 0.8rem;
}

.ConfirmPopover {
  @include control;
}
