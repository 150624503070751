.FluidButton {
  width: 100%;
  height: 4rem;
  border: 0;
  margin: 0;
  cursor: pointer;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: bold;
  line-height: 2rem;
  text-align: center;
}

.FluidButton span {
  height: 2rem;
}

.FluidButton svg {
  height: 1.8rem;
}

.FluidButtonPrimary {
  background: var(--color-brand-button);
  color: var(--color-white);
}

.FluidButtonPrimary svg path {
  fill: var(--color-white);
}

.FluidButtonPrimary:hover,
.FluidButtonPrimary.FluidButtonActive {
  background: var(--color-button-active);
}

.FluidButtonPrimary:disabled {
  background: var(--color-disabled-background);
  color: var(--color-disabled-text);
}

.FluidButtonSecondary {
  border: 1px solid var(--color-gray);
  background-color: var(--color-bg-2);
  color: var(--color-button-secondary);
}

.FluidButtonSecondary:hover,
.FluidButtonSecondary.FluidButtonActive {
  border-color: var(--color-brand-button);
  color: var(--color-brand-main);
}

.FluidButtonSecondary:hover svg path,
.FluidButtonSecondary.FluidButtonActive svg path,
.FluidButtonTertiary:hover svg path,
.FluidButtonTertiary.FluidButtonActive svg path {
  fill: var(--color-brand-main);
}

.FluidButtonSecondary:disabled {
  border-color: var(--color-gray);
  color: var(--color-gray);
}

.FluidButtonTertiary {
  background: var(--color-bg-2);
  color: var(--color-button-gray);
}

.FluidButtonSecondary svg path,
.FluidButtonTertiary svg path {
  fill: var(--color-button-gray);
}

.FluidButtonTertiary:hover,
.FluidButtonTertiary.FluidButtonActive {
  color: var(--color-brand-main);
}

.FluidButtonTertiary:disabled {
  color: var(--color-gray);
}

.FluidButtonPrimary:disabled svg path,
.FluidButtonSecondary:disabled svg path,
.FluidButtonTertiary:disabled svg path,
.ButtonDisabled:disabled svg path {
  fill: var(--color-gray);
}

.FluidButtonIconVariant {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
