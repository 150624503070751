.TableRow {
  min-width: max-content;
  border-bottom: 0.06rem solid var(--grey-300);

  > td:nth-last-child(2) {
    padding-right: 2.4rem;
  }

  &:hover {
    background-color: var(--grey-100);
  }

  &:active,
  &:focus {
    border-bottom: 0.06rem solid var(--red-60);
    background-color: var(--grey-100);
    outline: none;
  }
}

.TableRowSelected {
  border-bottom: 0.06rem solid var(--red-60);
  background-color: var(--grey-100);
  outline: none;
}

.TableCell {
  padding: 1.6rem 2.4rem;
}

table tbody td.TableRowSticky {
  position: sticky;
  z-index: z('popover');
  right: 0;
  // Hack to enforce min-content width for this column.
  width: 1%;
  padding: 0 !important;
  border-bottom: 0.06rem solid var(--grey-300);
  background-color: var(--grey-200);
  white-space: nowrap;
}
