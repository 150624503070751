@import 'src/styles/mediaQueries';

.StepFooter {
  display: flex;
  margin-top: 2.5rem;
}

.StepFooter_hidden {
  display: none;
}

.StepFooter_step6 {
  flex-direction: column;
  padding: 0;
  margin-top: 1.7rem;
}

.StepFooter_hours {
  flex-direction: column;
  align-items: center;
}

@include screens-above('md') {
  .StepFooter_hours {
    max-width: 57.8rem;
    flex-direction: row-reverse;
  }
}
