@import 'src/styles/mediaQueries';

.OuterWrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.Reimbursement {
  width: 100%;
  padding: 0.8rem;
  padding-top: 0;
  margin: 1.7rem 0 0 0;
  background-color: var(--color-bg-1);
  color: var(--color-text-main);
  font: 1.4rem 'Source Sans Pro', sans-serif;
}

.FileUploadWrapper {
  flex-direction: column;
  margin-bottom: 0.4rem;
}

.FileUpload {
  display: grid;
  margin: 0 0 0.8rem 0.4rem;
  color: var(--color-text-main);
  font-size: 1.7rem;
  line-height: 1;
}

.CloseReimburse {
  display: none;
}

.RemoveReimburseStep {
  width: 100%;
  border-color: var(--color-button-gray);
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
  background-color: var(--color-bg-2);
  color: var(--color-button-gray);
}

@include screens-above('md') {
  .OuterWrapper {
    flex-direction: row;
  }

  .Reimbursement:not(:first-of-type) {
    margin-top: 1.7rem;
  }

  .Reimbursement {
    padding-top: 0.8rem;
  }

  .CloseReimburse {
    display: flex;
    align-self: center;
    margin-left: 5.1rem;
  }

  .RemoveReimburseStep {
    display: none;
  }

  .Project {
    margin-right: 1rem;
  }

  .ReimburseType {
    margin-left: 0.5rem;
  }
}
