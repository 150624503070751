.header {
  & p {
    color: var(--grey-400);
  }

  &:nth-child(1) {
    margin-left: 0.35rem;
  }
}

.grid {
  padding: 0.57em;

  & div[class*='row']:not(:last-child) {
    margin-bottom: 0.35rem;
  }

  & div[class*='row']:nth-child(even) {
    background: var(--grey-200);
  }
}

.removeBackground {
  background: transparent;
}
