.Table {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--grey-100);
  padding: 16px 16px 0;
  width: 100%;
  margin-bottom: 24px;
}

.Column {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  padding-bottom: 16px;
}

.LabelText {
  font-weight: bold;
  font-size: 14px;
  color: var(--color-black-gray);
  padding-bottom: 8px;
  font-weight: 700;
}

.LabelValue {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--grey-600);
  max-width: 100%;
  word-break: break-word;
}

.Message {
  font-weight: bold;
  margin: 2rem 0;
}

.LastChange {
  width: 100%;
  margin-top: 0.5rem;
}

.EditButton {
  border: 1px solid var(--color-button-gray);
  color: var(--color-button-gray);
  font-weight: 700;
  padding: 12px 22px;
  background-color: var(--color-bg-2);
  outline: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .Column {
    width: 100%;
  }

  .EditButton {
    width: 100%;
    padding: 12px 0;
  }
}
