@import 'src/styles/mediaQueries';

.LastEditRow {
  margin-bottom: 0;
}

.ExpandButton {
  display: flex;
  padding: 0;
  border: none;
  margin-top: 5px;
  margin-left: 0.5rem;
  background-color: transparent;
  cursor: pointer;
}

.ExpandButton:focus {
  outline: 0;
}

.CollapseImage {
  width: 100%;
  height: auto;
  filter: invert(75%) sepia(0%) saturate(5384%) hue-rotate(273deg) brightness(86%) contrast(82%);
  opacity: 0.3;
}

.LabelDate {
  display: flex;
  align-items: center;
  color: var(--color-button-gray);
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  opacity: 1;
}

@include screens-above('md') {
  .LabelDate {
    font-size: 1.4rem;
  }
}
