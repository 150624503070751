@import 'src/styles/mediaQueries';

.Table {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Column {
  display: flex;
  width: 50%;
  flex-direction: column;
  padding-bottom: 16px;
}

.FullColumn {
  @extend .Column;

  min-width: 100%;
}

.LabelText {
  padding-bottom: 0.5rem;
  color: var(--color-black-gray);
  font-size: 14px;
  font-weight: bold;
  font-weight: 700;
}

.Input {
  width: calc(100% - 10px);
  flex: 1 1 auto;
  margin: 0;
}

.Textarea {
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 0.8rem;
}

.Textarea textarea {
  width: 100%;
  height: 5.4rem;
  padding: 1.7rem;
  border: 1px solid var(--color-border);
  color: var(--color-text-main);
}

.ButtonWrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 0.8rem;
}

.SaveButton {
  padding: 1.2rem 2.2rem;
  border: none;
  background-color: #ff5964;
  color: white;
  cursor: pointer;
  font-weight: 700;
  outline: none;
}

@media screen and (max-width: 768px) {
  .Input {
    flex: 1 1 auto;
  }

  .Column {
    width: 100%;
  }
}
