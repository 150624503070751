.inputResultContainer {
  position: absolute;
  width: 100%;
  max-height: 37rem;
  padding: 0.4375rem 0.5rem 0.5rem 0.5rem;
  border: 0.1rem solid var(--grey-500);
  background-color: var(--white);
  overflow-y: auto;
  z-index: 10;

  &:focus {
    border: 2px solid red;
  }
}

.CommonResultItemLabel {
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.25rem;
}

.resultItemLabel-with-icon {
  composes: CommonResultItemLabel;
  margin-top: 8px;
}

.resultItemLabel-without-icon {
  composes: CommonResultItemLabel;
}

.iconContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.inputResultItemContainer {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 6px;
  cursor: pointer;

  &:hover {
    background: var(--grey-100);
  }
}
