@import 'src/styles/mediaQueries';

.SummaryRow {
  composes: SummaryRow from './Shared.module.scss';
}

.SummaryLabel {
  composes: SummaryLabel from './Shared.module.scss';
}

.SummaryCalculation {
  composes: SummaryCalculation from './Shared.module.scss';
}

.SummaryCalculationTotal {
  composes: SummaryCalculationTotal from './Shared.module.scss';
}

.legendSmall,
.legendLarge {
  min-width: 0.8rem;
  flex: 1;
  margin: 0;
}

.legendSmall {
  min-width: 36%;
  flex: 1;
  white-space: nowrap;
}

.legendSmall span {
  display: inline-flex;
}

.legendRow {
  margin-bottom: 1.2rem;
  font: 400 1.4rem 'Source Sans Pro', sans-serif;
}

.labelRate {
  display: none;
  border: none;
}

.inputHours,
.labelRate,
.labelProject,
.labelProjectHours {
  width: 100%;
  height: 5.4rem;
  padding: 1.7rem;
  border: 1px solid var(--color-bg-3);
  margin: 0;
  font: 400 1.4rem 'Source Sans Pro', sans-serif;
}

.labelProject,
.labelProjectHours {
  display: flex;
  flex: 2;
  align-items: center;
  padding: 0 1.7rem;
}

.inputHours {
  flex: 1;
}

.inputRow {
  height: 5.4rem;
  margin-bottom: 1.2rem;
}

.taskRowContainer {
  width: 100%;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  margin: -1rem 0 2rem 0;
  background-color: var(--color-gray-slate);
  border-radius: 4px;
}

.taskRowContainer > div {
  width: 100%;
}

.modalTitle {
  margin: 0 0 4rem 2rem;
  color: var(--color-text-title);
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.5rem;
}

.modalSecondaryButton {
  display: none;
}

.bottomMsg {
  margin: 1.7rem auto 3.4rem;
}

.titleRow {
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.nonExistingProjectsContainer {
  margin: -1rem 0 2rem 0.8rem;
}

.nonExistingProjectsText {
  color: var(--color-brand-main);
  font: 600 1.4rem 'Source Sans Pro', sans-serif;
  user-select: none;
}

.overTimeApprover {
  display: flex;

  > span {
    margin-left: 0.4em;
    font-weight: bold;
  }
}

.detachedRow {
  padding: 0 0 3rem 0.5rem;
  font-size: smaller;
}

/* From Tablet */
@include screens-above('md') {
  .legendSmall {
    min-width: 0;
    flex: 3;
    white-space: normal;
  }

  .legendRow {
    max-width: 57.8rem;
    align-items: center;
    font: 400 1.7rem 'Source Sans Pro', sans-serif;
  }

  .legendLarge {
    flex: 3;
  }

  .legendSmall span {
    display: none;
  }

  .labelProject {
    flex: 3;
    margin-right: 0.8rem;
    background-color: var(--color-bg-2);
  }

  .workHoursAlert {
    width: fit-content;
    margin-bottom: 0;
    color: var(--color-brand-main);
    line-height: 1;
  }

  .inputHours {
    flex: 2;
  }

  .labelRate {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .inputRow {
    max-width: 57.8rem;
    height: 6.5rem;
    padding: 0.5rem;
    background-color: var(--color-bg-1);
  }

  .bottomMsg {
    height: 6rem;
    padding: 1.7rem;
    margin: 0;
  }

  .nextButton {
    min-width: 17rem;
    margin-right: 3.4rem;
  }

  /* collapsible container */
  .taskRowContainer {
    width: 78.9%;
  }
}
