@import 'src/styles/mediaQueries';

.Card {
  position: relative;
  display: flex;
  width: 30.4rem;
  flex-direction: column;
  background-color: var(--color-bg-1);
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0.4rem 0.2rem var(--color-shadow);
  }

  .SpotlightBackground {
    position: relative;
    display: flex;
    height: 32rem;
    background-size: cover;
  }

  .CrowIconGroup {
    position: absolute;
    display: flex;
    max-width: 22.5rem;
    align-items: center;
    justify-content: center;
    padding: 0.6rem 1.2rem;
    margin: 1.6rem 0 0 1.6rem;
    background: var(--color-brand-main);

    > .CrownIcon {
      width: 2rem;
      margin-right: 0.6rem;
    }

    > .Title {
      color: var(--color-white);
      font: 700 1.5rem/1 'Open Sans', sans-serif;
      font-size: 1.4rem;
      line-height: 2rem;
      text-transform: capitalize;
    }
  }

  .CrownGroup {
    position: absolute;
    bottom: 1.6rem;
    left: 1.6rem;

    .Username {
      margin-bottom: 1rem;
      color: var(--color-white);
      font-family: 'Prompt', 'Open Sans', sans-serif;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 2.2rem;
      text-transform: capitalize;
    }
  }

  .SpotlightBackground::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background:
      linear-gradient(
        180deg,
        rgba(var(--color-rgb-black), 0.04),
        rgba(var(--color-rgb-black), 0.34)
      );
    content: '';
  }

  @include screens-above('sm') {
    .SpotlightBackground {
      height: 43.8rem;
    }
  }
}

@include screens-above('md') {
  .CrowIconGroup {
    max-width: 30rem !important;
  }
}

.Modal {
  padding: 0;
}

.ModalContent {
  display: grid;
  gap: 2rem;
  grid-template-columns: 50% 1fr;

  .Content {
    height: 100%;
    padding: 0 2rem 4rem;
  }

  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ModalHeading {
  padding-bottom: 1rem;
  border-bottom: 0.5rem solid var(--color-brand-main);
  margin-bottom: 2rem;
}
