@import 'src/styles/mediaQueries';

.searchExpandButton {
  display: flex;
  align-items: center;
  padding: 0.9rem;
  border: none;
  margin-right: 1.6rem;
  background: none;
  cursor: pointer;

  svg {
    fill: var(--black);
    transition: all 100ms ease-in-out;
  }

  @include screens-below('xs') {
    margin-right: 0.6rem;
  }

  &:hover {
    svg {
      fill: var(--red-60);
    }
  }
}

.inputWrapper {
  position: relative;
  width: 32rem;
  margin-right: 1.6rem;

  @include screens-above('md') {
    width: 48rem;
  }

  @include screens-above('lg') {
    width: 56rem;
  }
}

.divider {
  min-height: 2rem;
}

.searchIconContainer {
  display: flex;
  height: 2rem;
  align-items: center;
  margin-left: 0.8rem;

  svg {
    fill: var(--black);
  }

  .divider {
    border-right: 0.1rem solid var(--grey-800);
    margin-left: 0.8rem;
  }
}

.closeButtonContainer {
  display: flex;
  height: 2rem;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    padding: 0.9rem;
    border: none;
    background: none;
    cursor: pointer;
  }

  svg {
    fill: var(--grey-400);
  }

  .divider {
    border-right: 0.1rem solid var(--grey-300);
  }
}
