@import 'src/styles/mediaQueries';

.ProgressBar_Container {
  position: relative;
  border-bottom: 3.4rem solid transparent; /* fake space for absolutely positioned titles */
  margin: 0 0.6rem;
}

.ProgressBar_Line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background: var(--color-border);
  transform: translateY(-50%);
}

.ProgressBarStep_ProgressLine {
  background: var(--color-brand-main);
}

.ProgressBar_Line_Container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ProgressBar_Steps_Container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@include screens-above('md') {
  .ProgressBar_Container {
    position: relative;
    margin: 0;
  }
}
