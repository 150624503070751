.OwnerInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.TableLabel {
  color: var(--grey-800);
  font-family: var(--font-family-source-sans-pro);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.428rem;
}
