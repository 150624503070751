@import './styles/overrides';
@import './design-system/theme/index.module.scss';

:root {
  --font-family-prompt: 'Prompt', 'Open Sans', sans-serif;
  --font-family-source-sans-pro: 'Source Sans Pro', 'Open Sans', sans-serif;
}

// @Deprecated, use Theme colors instead
$brand-color: #ff5964;

[data-theme='light'] {
  --color-brand-main: #{$brand-color};
  --color-brand-brighter: #fd6d4a;
  --color-brand-border: #ffeaed;
  --color-brand-button: #{$brand-color};
  --color-nav-icon: #5a5665;
  --color-text-main: #5a5665;
  --color-text-title: #34323b;
  --color-text-dark: #5a5665;
  --color-text-tag: #fff;
  --color-text-nav-active: #ff5964;
  --color-white: #fff;
  --color-black: #000;
  --color-border: #e9edee;
  --color-shadow: #f0f0f0;
  --color-bg-1: #f9f9f9;
  --color-bg-2: #fff;
  --color-bg-3: #f9f9f9;
  --color-bg-nav-active: #ffeaed;
  --color-bg-default: #fff;
  --color-private-bg: #b2b6bc;
  --color-tag-bg: #9a9d9e;
  --color-button-gray: #9a9d9e;
  --color-button-secondary: #9a9d9e;
  --color-gray-input: #dfdfdf;
  --color-light-gray: #e6e6e6;
  --color-text-gray: #b2b6bc;
  --color-yellow-bg: #f8e71c;
  --color-green: #008000;
  --color-green-bg: #95e97a;
  --color-green2-bg: #32bf00;
  --color-light-green-bg: #eeffe8;
  --color-orange-bg: #fa0;
  --color-red-bg: #e54a58;
  --color-red: #e10000;
  --color-crimson: #dc143c;
  --color-blue-light: #a7d9ff;
  --color-blue-light-gray: #f9fafb;
  --color-gray-slate: #929fba;
  --color-black-gray: #000;
  --color-black-gray-title: #000;
  --color-invoice-approved: #32bf00;
  --color-invoice-submitted: #ff5964;
  --color-invoice-notset: #9a9d9e;
  --color-invoice-queued: #32bf00;
  --color-invoice-processing-payment: #eb9b00;
  --color-invoice-paid: #228100;
  --color-invoice-failed: #6a27d8;
  --color-item-rarity: #298eff;
  --color-chart-block: #f8f9fa;
  --color-chart-btn-active: #28a745;
  --color-card-shadow: rgba(200, 200, 200, 0.5);
  --color-gray: #e6e6e6;
  --color-disabled-text: #e5e5e5;
  --color-disabled-background: #f3f3f3;
  --color-gray-background: #aca9b5;
  --color-button-active: #e02242;
  --color-main-hover: #f35a57;
  --color-white-border: #e8edee;

  /* for use in rgba() */
  --color-rgb-nav: 0, 0, 0;
  --color-rgb-black: 0, 0, 0;
  --color-rgb-dark-purple: 52, 50, 59;
  --color-rgb-gray: 200, 200, 200;
  --color-rgb-gray-blue: 171, 183, 193;
  --color-rgb-pink: 246, 58, 86;
  --color-rgb-green2-bg: 50, 191, 0; /* same color as --color-green2-bg */
  --color-error-yellow: #fde999;
  --color-error-yellow-dark: #ebdb99;
}

[data-theme='dark'] {
  --color-brand-main: #{$brand-color};
  --color-brand-brighter: #fd6d4a;
  --color-brand-border: #272424;
  --color-brand-button: #b3000c;
  --color-nav-icon: #f3f3f3;
  --color-text-main: #f3f3f3;
  --color-text-title: #f3f3f3;
  --color-text-dark: #5a5665;
  --color-text-tag: #020202;
  --color-text-nav-active: #fff;
  --color-white: #fff;
  --color-black: #000;
  --color-border: #5a5665;
  --color-shadow: #020202;
  --color-bg-1: #000;
  --color-bg-2: #19191b;
  --color-bg-3: #262629;
  --color-bg-nav-active: #5a5665;
  --color-bg-default: transparent;
  --color-private-bg: #3a3a3a;
  --color-tag-bg: #b9bbbd;
  --color-button-gray: #9a9d9e;
  --color-button-secondary: #dfdfdf;
  --color-gray-input: #dfdfdf;
  --color-light-gray: #474747;
  --color-text-gray: #b2b6bc;
  --color-yellow-bg: #968c11;
  --color-green: #008000;
  --color-green-bg: #95e97a;
  --color-green2-bg: #32bf00;
  --color-light-green-bg: #eeffe8;
  --color-orange-bg: #fa0;
  --color-red-bg: #e54a58;
  --color-red: #e10000;
  --color-crimson: #dc143c;
  --color-blue-light: #a7d9ff;
  --color-blue-light-gray: #f9fafb;
  --color-gray-slate: #929fba;
  --color-black-gray: #9a9d9e;
  --color-black-gray-title: #f3f3f3;
  --color-invoice-approved: #32bf00;
  --color-invoice-submitted: #ff5964;
  --color-invoice-notset: #9a9d9e;
  --color-invoice-queued: #32bf00;
  --color-invoice-processing-payment: #eb9b00;
  --color-invoice-paid: #228100;
  --color-invoice-failed: #6a27d8;
  --color-item-rarity: #49c3ff;
  --color-chart-block: #030303;
  --color-chart-btn-active: #28a745;
  --color-card-shadow: rgba(200, 200, 200, 0.5);
  --color-gray: #313132;
  --color-disabled-text: #5a5a5a;
  --color-disabled-background: #313132;
  --color-gray-background: #aca9b5;
  --color-button-active: #e02242;
  --color-main-hover: #f35a57;
  --color-white-border: #e8edee;

  /* for use in rgba() */
  --color-rgb-nav: 0, 0, 0;
  --color-rgb-black: 0, 0, 0;
  --color-rgb-dark-purple: 52, 50, 59;
  --color-rgb-gray: 200, 200, 200;
  --color-rgb-gray-blue: 0, 0, 0;
  --color-rgb-pink: 246, 58, 86;
  --color-rgb-green2-bg: 50, 191, 0; /* same color as --color-green2-bg */
  --color-error-yellow: #fde999;
  --color-error-yellow-dark: #ebdb99;
}

html {
  height: 100%;
  min-height: 100%;

  @include font;
  @include scrollbar(#ff5964);
}

body {
  min-width: 320px;
  height: 100%;
  min-height: 100%;
  background: var(--color-bg-1);
  font-size: 1.445rem;

  > div {
    height: 100%;
    min-height: 100%;
  }

  @include theme-globals($brand-color);
}
