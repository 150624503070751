@import 'src/styles/mediaQueries';

.Wrap {
  display: inline-grid;
  width: 100%;
  padding: 0.4rem;
}

.DropZone {
  display: flex;
  flex-direction: column;
  align-content: center;
  border: var(--color-brand-main) dotted;
  cursor: pointer;
  font: 1.4rem 'Source Sans Pro', sans-serif;
}

.Wrap:hover .DropZone,
.dragover {
  background-color: var(--color-brand-border);
}

.Wrap .hintText {
  margin: 0 auto 1rem auto;
  font-size: 1.1rem;
  text-align: center;
}

.DropZoneList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.deleteIcon:hover {
  cursor: pointer;
}

.DropZoneListItem {
  display: flex;
  overflow: hidden;
  width: 24rem;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DownloadIcon {
  display: flex;
  width: 3rem;
  margin: 1.8rem auto;
}

.DeleteIconContainer {
  display: flex;
  transform: scale(0.7);
}

@include screens-above('md') {
  .Wrap_modal {
    padding-top: 1.4rem;
  }
}
