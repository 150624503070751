@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.ViewOnlyVaultItem {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid var(--color-border);
  background-color: var(--color-bg-1);

  & :global(.thumbs-wrapper) {
    display: none;
  }

  &:hover {
    box-shadow: 0 0 0.4rem 0.2rem var(--color-shadow);
  }
}

.ViewOnlyVaultItemRare {
  border-color: var(--rare-60);
}

.ViewOnlyVaultItemLegendary {
  border-color: var(--legendary-600);
}

.ViewOnlyVaultItemCommon {
  border-color: none;
}

.ViewOnlyVaultItemNoStock {
  filter: grayscale(100%);
}

.RarityIcon {
  width: 2.4rem;
  height: 2.4rem;
}

.ViewOnlyVaultItemHome {
  display: unset;
  width: 100%;
  flex: unset;
  flex-direction: unset;
  margin: 0;
}

@include screens-above('md') {
  .ViewOnlyVaultItemHome {
    width: 100%;
  }
}

.ImageCarousel,
:global(.slide) {
  background-color: var(--color-bg-1) !important;
}

.ImageCarousel {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 0;
  align-items: center;
  justify-content: center;
  padding-top: calc(320 / 222 * 100%);
  background-size: cover !important;

  & > img {
    max-width: 100%;
    height: auto;
  }

  &::after {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    background:
      linear-gradient(
        180deg,
        rgba(var(--color-rgb-black), 0.04) 0%,
        rgba(var(--color-rgb-black), 0.34) 100%
      );
    content: '';
  }
}

.TitleBox {
  position: absolute;
  bottom: 1.6rem;
  width: 100%;
  padding: 0 1.6rem;
}

.TitleContent {
  display: flex;
}

.Title {
  flex: 1;
  margin: 0;
  margin-left: 1rem;
  color: var(--color-white);
  text-transform: uppercase;
}
