.warning {
  padding: 10px;
  margin-bottom: 1.14rem;
  background-color: var(--red-20);
  color: var(--red-60);
}

.questionLink {
  display: inline-block;
  margin: 0;
  color: var(--red-60);
}

.question {
  display: inline;
  margin: 0;
}

.nonExistingProjectsContainer {
  margin: -1rem 0 2rem 0.8rem;
}

.nonExistingProjectsText {
  color: var(--color-brand-main);
  font: 600 1.4rem 'Source Sans Pro', sans-serif;
  user-select: none;
}
