@import 'src/styles/mediaQueries';

.TableDraggableRow {
  .TableDraggableRowIcon {
    display: flex;
    width: 4rem;
    max-width: 4rem;
    height: 4rem;
    max-height: 4rem;
    align-items: center;
    justify-content: center;
    user-select: none;
    visibility: hidden;

    &:hover {
      cursor: grab;

      button {
        cursor: grab;
      }
    }

    &:active {
      cursor: grabbing;

      button {
        cursor: grabbing;
      }
    }

    button {
      user-select: none;
    }

    @include screens-below('sm') {
      width: 2rem;
      max-width: 4rem;
      height: 4rem;
      max-height: 4rem;
      grid-column: span 4rem;
      visibility: visible;
    }
  }

  &:hover {
    .TableDraggableRowIcon {
      visibility: visible;
    }
  }
}
