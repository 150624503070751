@import '../../../../design-system/theme/index.module.scss';

.wrapper {
  position: absolute;
  top: 4rem;
  width: 100%;
  max-height: 70rem;
  padding: 1.2rem 0;
  border: 0.1rem solid var(--color-border);
  background-color: var(--white);
  overflow-y: scroll;

  .listHeader {
    padding: 0 1.2rem;
    color: var(--grey-500);

    &.projectsListHeader {
      margin-top: 0.8rem;
    }

    span {
      display: inline-block;
      margin-bottom: 0.8rem;
      font-size: 1.2rem;
    }
  }

  .listItemSelected {
    background-color: var(--grey-200) !important;
  }

  .listItem {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.8rem 1.2rem;
    border: none;
    background: none;
    cursor: pointer;

    &.projectListItem {
      padding: 1.2rem;
    }

    &:hover {
      background-color: var(--grey-200) !important;
    }

    span {
      text-align: center;
    }

    span:not(:first-child) {
      display: inline-block;
      margin-left: 0.8rem;
    }

    .active {
      width: 0.8rem;
      height: 0.8rem;
      margin-left: 0.8rem;
      background: var(--utility-green-80);
      border-radius: 3.2rem;
      box-shadow: 0 0 0.8rem rgba(50, 191, 0, 0.48);
    }

    .inactive {
      width: 0.8rem;
      height: 0.8rem;
      margin-left: 0.8rem;
      background: var(--red-60);
      border-radius: 3.2rem;
      box-shadow: 0 0 0.8rem rgba(255, 89, 100, 0.48);
    }

    .projectManager {
      overflow: ellipsis;
      color: var(--grey-400);
    }
  }
}
