@import 'src/styles/zIndex';
@import 'src/styles/mediaQueries';

.Toast {
  position: fixed;
  z-index: z('toast');
  top: 7.2rem;
  left: 50%;
  width: calc(100% - 1.6rem);
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  transform: translate(-50%, 0);

  & > p {
    padding: 1.6rem;
  }

  @include screens-above('lg') {
    top: 1.6rem;
    width: 63.2rem;
  }

  * {
    box-sizing: border-box;
  }
}

.TopCenter {
  top: 2rem;
}

.BottomCenter {
  bottom: 2rem;
}
