.plusIcon {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 1rem;
  background-color: var(--grey-400);
  -webkit-mask-image: url(../../assets/a-plus-button.svg);
  mask-image: url(../../assets/a-plus-button.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 1.2rem 1.2rem;
  mask-size: 1.2rem 1.2rem;
}

.ReimHeader {
  composes: ReimHeader from './Shared.module.scss';
}

.UnleashBudgetError {
  composes: UnleashBudgetError from './Shared.module.scss';
}

.LabelProject {
  width: 24rem;
  padding-left: 1rem;
}

.LabelReimburse {
  width: 21rem;
}

.LabelUnit {
  width: 16rem;
  padding-left: 2rem;
}

.LabelQuantity {
  width: 16rem;
  padding-left: 3rem;
}

.AddReimbursementButton {
  width: 100%;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  line-height: 4.1rem;
  text-transform: none;
}
