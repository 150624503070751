@import 'src/styles/mediaQueries';

.Menu {
  overflow: hidden;
  width: 23.4rem;
  background: var(--color-bg-2);
  box-shadow: 0.1rem 0 0 rgba(var(--rgb-black), 0.08);
  font-size: 1.4rem;
  transition: width 0.2s ease, transform 0.2s ease;
  white-space: nowrap;
}

.Divider {
  margin: 0;
}

.ItemsList {
  padding: 0;
  margin: 0;
}

.SesionInfo {
  display: flex;
  height: 6.4rem;
  flex-direction: column;
  padding: 1.4rem 1.6rem;
  background-color: var(--grey-100);
  gap: 0.4rem;

  & p {
    margin: 0;
  }
}

@include screens-below('lg') {
  .Menu {
    width: 6.4rem;
    transform: translate(0, 0);
    transition: width 0.2s ease;

    & p {
      visibility: hidden;
    }

    & .SesionInfo {
      display: none;
    }

    &:hover {
      width: 23.4rem;

      & p {
        visibility: visible;
      }
    }
  }
}
