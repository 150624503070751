@import 'src/styles/mediaQueries';

.ButtonContainer {
  @include screens-above('md') {
    display: flex;
    margin: 1.6rem 0;
    grid-column-gap: 0.8rem;
  }
}

.Description {
  margin: 0;
  margin-top: 1.6rem;
  color: var(--grey-500);
  font-size: 1.4rem;
  font-weight: 400;
  overflow-y: auto;

  h2 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
  }

  ul {
    padding-left: 2.4rem;
    margin-top: 0;
    margin-bottom: 2.4rem;
  }

  li::marker {
    font-size: 1rem;
  }
}

.ReferralCard > div:first-child > p {
  @include screens-below('md') {
    font-size: 2.2rem;
  }
}

.OpenJobDetails {
  overflow-y: auto;
}

.ModalContent {
  display: block;
  height: 100vh;
  padding: 0;
  margin-bottom: 0;
  box-shadow: none;
}

.ModalFooter {
  margin-top: 0;
}
