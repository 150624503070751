[data-theme='dark'] {
  .XhqDateRangePicker {
    :global .DateInput_fangStroke {
      fill: var(--color-bg-2);
      stroke: var(--grey-300);
    }

    :global .DayPicker__horizontal {
      overflow: hidden;
      border: 1px solid var(--grey-300);
    }
  }
}

.XhqDateRangePicker {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;

  :global .DayPicker,
  :global .CalendarDay,
  :global .CalendarMonth,
  :global .CalendarMonthGrid {
    background-color: var(--color-bg-2);
  }

  :global .DateRangePicker_picker {
    background-color: transparent;
  }

  :global .DateRangePickerInput {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    border: solid 1px var(--grey-200);
    background-color: var(--color-bg-2);
    border-radius: 0;
  }

  :global .DateInput {
    width: 100%;
    height: 100%;
  }

  :global .DateInput_input {
    height: 100%;
    padding: 0 11px;
    border: none;
    background-color: var(--white);
    color: var(--grey-800);
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.4rem !important;
    font-weight: 400;
    text-align: center;
  }

  :global .DateInput_input__focused {
    border: none;
  }

  :global .DateRangePickerInput_arrow::after {
    display: inline-block;
    width: 4px;
    color: var(--grey-800);
    content: '-';
  }

  :global .DateRangePickerInput_arrow_svg {
    display: none;
  }

  :global .DateRangePickerInput_calendarIcon,
  :global .DateRangePickerInput_clearDates {
    display: flex;
    margin-left: auto;
  }

  :global .DateRangePickerInput_clearDates {
    position: relative;
    top: unset;
    right: unset;
    margin: 0;
    margin-left: auto;
    transform: unset;

    svg > path {
      fill: var(--grey-400);
    }
  }

  :global .DateRangePickerInput__showClearDates {
    padding-right: 0;
  }

  :global .DateRangePickerInput_calendarIcon {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem 0 1rem;
    margin: 0;
    background-color: var(--color-bg-2);
  }
}

.XhqDateRangePicker__disabled {
  :global .DateRangePickerInput,
  :global .DateInput_input__disabled {
    background-color: var(--grey-100);
  }

  :global .DateInput {
    width: 8rem;
  }

  :global .DateInput_input__disabled {
    color: var(--grey-300);
    font-style: normal;
  }

  :global .DateRangePickerInput_arrow::after {
    color: var(--grey-300);
  }
}

.XhqDateRangePicker__error {
  :global .DateRangePickerInput {
    border-color: var(--red-60);
  }
}

.XhqDateRangePicker__fullWidth {
  display: block;
  width: 100%;

  :global .DateRangePicker {
    width: 100%;
  }

  :global .DateRangePickerInput {
    display: flex;
    width: 100%;
  }
}

.XhqDateRangePicker__calendar_left_navigation {
  position: relative;
}

.XhqDateRangePicker__calendar_left_navigation_mobile {
  transform: translateX(-140px);
}

.XhqDateRangePicker__calendar_right_navigation {
  position: relative;
}

.XhqDateRangePicker__calendar_right_navigation_mobile {
  transform: translateX(105px);
}

.XhqDateRangePicker__label {
  margin-top: 0;
  margin-bottom: 8px;
  color: var(--grey-400) !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
}

.XhqDateRangePicker__label_error {
  color: var(--red-60);
}

.XhqDateRangePicker__pristine {
  :global .DateInput {
    width: 100%;
  }

  :global .DateRangePickerInput_arrow::after {
    color: var(--grey-400);
  }
}

.XhqDateRangePicker__selected {
  :global .DateRangePickerInput {
    border: 1px solid var(--grey-500);
  }
}

:global .DateRangePicker_picker {
  :global .DayPicker,
  :global .CalendarDay,
  :global .CalendarMonth,
  :global .CalendarMonthGrid {
    background-color: var(--color-bg-2);
  }

  :global .DayPickerNavigation_button__horizontal {
    position: absolute;
    top: 14px;
    left: 26px;
    padding: 6px 9px;
  }

  :global .DayPickerNavigation_button__horizontal:last-child {
    right: 26px;
    left: unset;
  }

  :global .DayPicker_weekHeader_li > small {
    color: var(--grey-600);
    font-size: 1.4rem;
    font-weight: 700;
  }

  :global .CalendarMonth_caption {
    color: var(--grey-600);
    font-size: 1.6rem;
  }

  :global .CalendarDay__default {
    border: none;
    color: var(--grey-600);
    font-size: 1.4rem;
  }

  :global .CalendarDay__blocked_out_of_range,
  :global .CalendarDay__default.CalendarDay__blocked_out_of_range:hover {
    background-color: transparent;
    color: var(--grey-300);
    cursor: default;
  }

  :global .CalendarDay__selected {
    background-color: var(--red-60);
    color: var(--white);
  }

  :global .CalendarDay__selected_span,
  :global .CalendarDay__hovered_span,
  :global .CalendarDay__default:hover {
    background-color: var(--red-20);
    color: var(--black);
  }
}

:global .DateRangePicker_picker__fullScreenPortal {
  background-color: var(--color-bg-2);

  .DateRangePicker_closeButton {
    display: none;
  }

  :global .DayPicker {
    padding-top: 1.5rem;
  }

  :global .DayPickerNavigation_button__vertical {
    position: absolute;
    top: 20px;
    left: 50%;
    padding: 6px 9px;
  }
}

:global .DateRangePicker_picker__portal {
  z-index: 900;
}

.Required {
  color: var(--red-60);
  font-weight: bold;
}
