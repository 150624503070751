@import './colors.module.scss';
@import './spacing.module.scss';

@mixin font {
  font: 400 62.5% / 1.5 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin theme-globals($main-color) {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    color: $main-color;
    text-decoration: none;
  }

  color: var(--grey-600);

  :focus {
    outline: 1px dashed $main-color;
  }

  ::placeholder {
    color: var(--grey-400);
  }

  ::selection {
    background: var(--grey-600);
    color: var(--white);
  }

  --shadow: 0 0 24px rgba(0, 0, 0, 0.12);
}

@mixin scrollbar($color) {
  ::-webkit-scrollbar {
    width: 0.5rem;
    background-color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 8.5rem;

    &:hover {
      background: rgba($color: $color, $alpha: 0.2);
    }
  }

  ::-webkit-scrollbar-thumb:vertical {
    background: rgba($color: $color, $alpha: 0.7);
    -webkit-border-radius: 8.5rem;

    &:active {
      background: rgba($color: $color, $alpha: 0.8);
      -webkit-border-radius: 8.5rem;
    }
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

[data-theme='light'] {
  color-scheme: light;

  @extend .light;
}

[data-theme='dark'] {
  color-scheme: dark;

  @extend .dark;
}
