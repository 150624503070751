@import 'src/styles/mediaQueries';

.StepTitle {
  position: relative;
  display: flex;
  padding: 0 0 0.8rem 0;
  border-bottom: 1px solid var(--color-bg-3);
  margin: 1.7rem 0 1.7rem 0;
  color: var(--color-button-gray);
  font: 900 1.7rem / 1.8 'Open Sans', sans-serif;
  text-transform: uppercase;
}

.StepTitle_active {
  color: var(--color-text-main);
}

.Number {
  margin-right: 0.8rem;
  font-weight: 800;
}

.StepTitle_active.StepTitle_red,
.StepTitle_active .Number {
  color: var(--color-brand-main);
}

.StepTitle_red {
  font-weight: 800;
}

.StepTitle_nopadding {
  padding: 0;
}

.StepTitle_faq {
  padding-bottom: 1em;
  margin: 4em 0 2em;
  color: var(--color-text-main);
  line-height: 1.2;
}

@include screens-above('md') {
  .StepTitle {
    display: flex;
    align-content: center;
    margin: 3.4rem 0 1.7rem 0;
    font: 900 2.2rem / 1.4 'Open Sans', sans-serif;
  }
}
