.Ticket {
  padding: 0 1rem 0 1.5rem;
}

.User {
  display: flex;
  flex-direction: column;

  & > p {
    margin: 0;
  }
}

.TicketUserName {
  color: var(--color-bg-inverted-2);
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}

.TicketDate {
  color: var(--grey-400);
  font-size: 1.4rem;
  font-weight: 400;
}

.Comment {
  padding-left: 1.5rem;
}
