.Pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4.1rem 0;
  gap: 0.4rem;
}

.Item {
  display: flex;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    color: var(--grey-400);
  }

  svg path {
    fill: var(--grey-400);
  }
}

.Border {
  border: 1px solid var(--grey-300);
}

.Item:hover,
.ItemActive {
  border: 1px solid var(--red-60);
  background-color: var(--red-60);

  p {
    color: var(--white);
  }
}
