.userListContainer {
  display: flex;
  align-items: center;
}

.userAvatar {
  margin-right: 0.8rem;
}

.userName {
  margin: 0;
  margin-right: auto;
  color: var(--black);
}

.followIcon {
  path {
    fill: var(--red-60);
  }
}

.followingIcon {
  path {
    fill: var(--grey-400);
  }
}
