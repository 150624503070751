@import 'src/styles/mediaQueries';

.InvoiceTotalContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  .Label {
    margin: 0;
    text-align: left;
  }

  .Price {
    margin: 0;
    color: var(--red-60);
    text-align: right;
  }

  @include screens-above('xl') {
    flex-direction: column;
    gap: 10px;

    .Label {
      text-align: right;
    }
  }
}
