.StatusIndicator {
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: center;
}

.IconContainer {
  display: flex;
  margin-left: 0.6rem;
}

.Completed {
  background-color: var(--utility-green-20);

  & path {
    fill: var(--utility-green-80);
  }

  & p {
    color: var(--utility-green-80);
  }
}

.Uncompleted {
  background-color: var(--red-20);

  & path {
    fill: var(--red-60);
  }

  & p {
    color: var(--red-60);
  }
}
