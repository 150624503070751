.TableExpandableRow {
  & > td {
    padding: 0 !important;
    background-color: var(--grey-100);
  }

  & tr > td {
    background-color: var(--grey-100);
  }

  & thead > tr > th {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    border-top: 1px solid var(--red-60);
    background-color: var(--grey-100);
    font-size: 20px;
  }
}

.Button {
  width: 4rem;
}
