.Error {
  position: relative;
  display: inline-block;
  background-color: var(--color-error-yellow);
  border: 1px solid var(--color-error-yellow-dark);
  border-radius: 0.4rem;
  color: var(--color-text-dark);
  padding: 1.2rem 2.5rem 1.2rem 3.9rem;
}
.Error::before {
  position: absolute;
  display: block;
  content: '!';
  font-size: 3.4rem;
  font-weight: 700;
  top: calc(50% - 2.5rem);
  left: 1.7rem;
}
