.SelectItem {
  display: flex;
  align-items: center;
  padding: 0.2rem;
  gap: 0.8rem;
}

.SelectedItem {
  margin: 0;
}

.MultiValueRemove {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--grey-400);
    transform: scale(0.8);
  }
}

.MainWrapper {
  margin: 8px 0;
}

.MainContainer {
  position: relative;
  width: 100%;
  margin: 8px 0;
}

.MainContainerNoMargin {
  position: relative;
  width: 100%;
}

.Avatar {
  width: 2.4rem;
  height: 2.4rem;
}

.Label-required {
  display: inline-flex;
  margin: 0 0 0 0.4rem;
  color: var(--red-60);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.25rem;
  vertical-align: bottom;
}

.LabelWrapper {
  display: inline-flex;
  padding: 0;
  vertical-align: bottom;
}

.Label {
  margin-right: 4px;
  color: var(--grey-400) !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1.25rem;
}

.SelectResetStyle {
  margin: 0;

  .MainContainer {
    margin: 0;
  }
}
