@import 'src/styles/mediaQueries';

.CardsContainer {
  display: inline-grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);

  @include screens-below('md') {
    grid-template-columns: repeat(2, 1fr);
  }
}
