@import 'src/styles/mediaQueries';

.Title {
  padding: 0;
}

.Button {
  max-width: 30rem;
  white-space: break-spaces;

  p {
    text-align: center;
  }
}

.Paragraph {
  margin-bottom: 2.5rem;
}

.HideCloseButton {
  display: none;
}

.Modal {
  max-width: 45rem;
  padding: 4rem;
  margin-left: auto;

  @include screens-above('lg') {
    margin-left: 9.5rem;
  }

  @include screens-above('xxl') {
    margin-left: 25.5rem;
  }
}

@include screens-below('lg') {
  .Modal {
    width: calc(100% - 1rem);
  }

  .ModalContent,
  .ModalFooter {
    padding: 0;
  }

  .Button {
    max-width: 100%;
    max-height: 6rem;
    padding: 2rem;
    margin-bottom: 2rem;

    p {
      overflow: visible;
      padding: 0 4rem;
      white-space: break-spaces;
    }
  }
}
