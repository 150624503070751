@import '../../../styles/mediaQueries';

.Heading {
  margin: 0;
  color: var(--grey-800);
}

.MainText {
  margin: 1.6rem 0 0 0;
  color: var(--grey-600);
}

.ApproverInput {
  margin: 1.6rem 0 0 0;
}

.ApproverInput > input {
  padding: 1rem;
  background-color: var(--color-bg-2);
  color: var(--color-text-main);
  outline: none !important;
}
