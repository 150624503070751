.Modal > div {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
}

.H4 {
  margin: 2rem 0 4rem 0;
  color: var(--color-text-title);
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 4rem;
}

.MainText {
  color: var(--color-text-main);
}

.ApproverInput {
  padding: 1.2rem 2rem;
}

.ButtonContainer {
  display: flex;
  justify-content: space-between;

  button {
    width: 49%;
  }
}
