.Wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.label {
  display: inline-flex;
  padding: 0;
  vertical-align: bottom;
}

.paragraph {
  color: var(--grey-400);
}

.LabelWrapper {
  display: flex;
}
