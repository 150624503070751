.SelectParent > div > div > div {
  color: var(--color-text-main);
}

.SelectParent ::-webkit-scrollbar {
  display: inline;
}
.Select_value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 1 auto;
}
