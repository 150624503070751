.button {
  min-width: 8.4rem;
  height: 4rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.4rem;
  border: 1px solid transparent;
  margin: 0;
  appearance: none;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 2rem;
  text-align: left;
  transition: background 0.2s, color 0.2s, border-color 0.2s;

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & svg > path {
    transition: fill 0.2s;
  }

  &:focus {
    outline: 1px dashed var(--red-60);
  }

  &:disabled {
    cursor: default;

    &.disabled--svg {
      & svg {
        & path {
          fill: var(--grey-300);
        }
      }
    }
  }
}

.layout--autolayout {
  display: inline-flex;
  width: auto;
  max-width: 100%;
}

.layout--fluid {
  display: flex;
  width: 100%;
}

.variant--primary {
  background: var(--red-60);
  color: var(--white);

  & svg {
    & path {
      fill: var(--white);
    }
  }

  &:hover:not(:disabled) {
    background: var(--red-80);
  }

  &:disabled {
    background: var(--grey-200);
    color: var(--grey-300);

    & svg {
      & path {
        fill: var(--grey-300);
      }
    }
  }
}

.variant--secondary {
  border-color: var(--grey-300);
  background: var(--white);
  color: var(--grey-400);

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }

  &:hover:not(:disabled) {
    border-color: var(--red-60);
    color: var(--red-60);

    & svg {
      & path {
        fill: var(--red-60);
      }
    }

    & i {
      background-color: var(--red-60);
    }
  }

  &:disabled {
    border-color: var(--grey-300);
    color: var(--grey-300);
  }
}

.variant--success {
  background-color: var(--utility-green-80);
  color: var(--white);

  & svg {
    & path {
      fill: var(--white);
    }
  }

  &:hover:not(:disabled) {
    background: var(--utility-green-100);
  }
}

.variant--cancel {
  border-color: var(--red-40);
  background-color: var(--transparent);
  color: var(--red-40);

  & svg {
    & path {
      fill: var(--red-60);
    }
  }

  &:hover:not(:disabled) {
    background: var(--red-60);
    color: var(--white);
  }
}

.variant--tertiary {
  background: var(--black);
  color: var(--grey-400);

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }

  &:hover {
    & svg {
      & path {
        fill: var(--red-60);
      }
    }
  }

  &:hover:not(:disabled) {
    color: var(--red-60);
  }

  &:disabled {
    color: var(--grey-300);
  }
}

.variant--icon {
  min-width: unset;
  height: auto;
  padding: 0;
  background: var(--transparent);

  & svg {
    & path {
      fill: var(--black);
    }
  }

  &:hover:not(:disabled) {
    & svg {
      & path {
        fill: var(--red-60);
      }
    }
  }

  &:disabled {
    color: var(--grey-300);
  }
}

.variant--icon-primary {
  width: 4rem;
  min-width: unset;
  height: 4rem;
  padding: 0;
  background: var(--red-60);

  & svg {
    & path {
      fill: var(--white);
    }
  }

  &:hover:not(:disabled) {
    background-color: var(--red-80);
  }

  &:disabled {
    background-color: var(--grey-200);

    & svg {
      & path {
        fill: var(--grey-300);
      }
    }
  }
}

.variant--icon-secondary {
  width: 4rem;
  min-width: unset;
  height: 4rem;
  padding: 0;
  border-color: var(--grey-300);
  background: var(--white);

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }

  &:hover:not(:disabled) {
    border-color: var(--red-60);

    & svg {
      & path {
        fill: var(--red-60);
      }
    }
  }

  &:disabled {
    & svg {
      & path {
        fill: var(--grey-300);
      }
    }
  }
}

.variant--icon-tertiary {
  min-width: unset;
  height: auto;
  padding: 0;
  background: var(--transparent);

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }

  &:hover {
    & svg {
      & path {
        fill: var(--red-60);
      }
    }
  }

  &:disabled {
    color: var(--grey-300);
  }
}

.variant--icon-red {
  min-width: unset;
  height: auto;
  padding: 0;
  background: var(--transparent);

  & svg {
    & path {
      fill: var(--red-60);
    }
  }

  &:hover {
    & svg {
      & path {
        fill: var(--red-80);
      }
    }
  }

  &:disabled {
    color: var(--grey-300);
  }
}

.icon {
  height: 100%;
}

.icon-position--start {
  flex-direction: row;

  .icon {
    margin: 0 0.6rem 0 0;
  }
}

.icon-position--end {
  flex-direction: row-reverse;

  .icon {
    margin: 0 0 0 0.6rem;
  }
}
