@import 'src/styles/mediaQueries';

.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inputStyle {
  width: 100%;
  margin: 0;

  & div {
    margin: 0;

    & input {
      min-width: 0;
      border: 1px solid var(--grey-300) !important;
    }
  }
}

.grid {
  & div[class*='row'] {
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 0.35rem;
    }

    @include screens-below('md') {
      flex-wrap: wrap;
    }
  }

  & div[class*='column']:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.header {
  & p {
    color: var(--grey-400);
  }
}

@media screen and (max-width: 768px) {
  .grid {
    & div[class*='column']:nth-child(3) {
      display: none;
    }

    & div[class*='column']:nth-child(2) {
      margin-right: 0 !important;
    }
  }
}

.labelProjectHours {
  width: 100%;
  padding: 0.9rem;
  border: 1px solid var(--grey-300);
  margin: 0;
  color: var(--grey-500);
  font-size: 1.4rem;
}
