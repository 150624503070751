@import 'src/styles/mediaQueries';

.Header {
  display: inline-block;
  color: var(--grey-400);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 2rem;

  &.HeaderClickable {
    cursor: pointer;
  }
}

.Icon {
  position: relative;
  top: -0.07rem;
  height: 100%;
  margin-left: 0.7rem;

  &.IconActive {
    & path {
      fill: var(--black);
    }
  }

  &.IconInactive {
    & path {
      fill: var(--grey-300);
    }
  }
}
