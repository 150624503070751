@import 'src/styles/mediaQueries';

.Panel {
  width: 100%;
  padding: 0 1.6rem;
  background-color: var(--grey-100);
}

.Spaced {
  @extend .Panel;

  padding: 1.6rem;
}
