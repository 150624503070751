@import 'src/styles/mediaQueries';

.Card {
  display: grid;
  width: 100%;
  min-height: 5rem;
  align-items: center;
  background-color: var(--grey-100);
  grid-template-columns: 1fr 18rem;

  @include screens-below('md') {
    display: block;
    padding: 1rem;
  }
}

.notEditable {
  grid-template-columns: 1fr 10rem;
}

.Container {
  padding: 1.6rem;

  @include screens-below('md') {
    padding: 1rem;
  }
}

.Status {
  display: -webkit-box;
  overflow: hidden;
  max-width: calc(38.6rem - 2.2rem);
  margin-top: 0.3rem;
  -webkit-box-orient: vertical;
  font-size: 1.4rem;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
}

.Approved {
  color: var(--rare-100);
}

.Paid {
  color: var(--utility-green-100);
}

.Submitted {
  color: var(--featured-100);
}

.Name {
  display: -webkit-box;
  overflow: hidden;
  max-width: calc(38.6rem - 3.2rem);
  margin: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
}

.Description {
  @extend .Name;

  color: var(--grey-500);
  -webkit-line-clamp: 2;
}

.RightContainer {
  display: inline-flex;
  padding: 0.8rem;

  @include screens-below('md') {
    display: block;
  }
}

.AmountsContainer {
  display: flex;
  flex-direction: column;
  text-align: end;

  @include screens-below('md') {
    display: block;
  }
}

.AmountsTotal {
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: start;

  @include screens-below('md') {
    padding-left: 0;
  }
}

.AmountsDetail {
  margin: 0;
  text-align: start;
  white-space: nowrap;

  @include screens-below('md') {
    padding-left: 1rem;
  }
}

.Edit {
  margin-right: 1.5rem;
}

.ActionsContainer {
  display: inline-flex;
  padding-top: 2rem;
  padding-right: 1rem;
  padding-left: 1.5rem;

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }

  @include screens-below('md') {
    padding-top: 1rem;
  }
}

.MobileDivider {
  width: 90%;
  margin: auto;
}

.Heading {
  margin: 0;
  color: var(--grey-800);
}

.MainText {
  margin: 1.6rem 0 0 0;
  color: var(--grey-600);
}
