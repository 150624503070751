@import 'src/styles/mediaQueries';

.Layout {
  display: flex;
  justify-content: space-between;
  margin: 0 0.8rem;

  @include screens-above('lg') {
    margin: 0 1.6rem;
  }
}

.MainContent {
  width: 100%;
}

.Header {
  position: relative;
  margin-top: 1.6rem;
  margin-bottom: 4.8rem;

  @include screens-above('lg') {
    margin-top: -8rem;
    margin-bottom: 4.8rem;
  }
}

.HeaderCommon {
  width: 100%;
  font-weight: 800;
  text-align: center;
}

.HeaderSeason {
  color: var(--color-white);
  font-size: 5.6rem;
  font-style: italic;
  line-height: 5.6rem;
  -webkit-text-stroke: 2px #e5e5e5;

  @include screens-above('md') {
    font-size: 9.6rem;
    line-height: 10rem;
  }

  @include screens-above('xl') {
    font-size: 13.2rem;
    line-height: 12rem;
  }
}

.HeaderMotto {
  position: absolute;
  bottom: -1.2rem;
  color: var(--color-brand-main);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 4rem;
  font-style: italic;
  font-weight: 800;
  line-height: 3.6rem;

  @include screens-above('md') {
    font-size: 4.6rem;
    line-height: 4.8rem;
  }

  @include screens-above('lg') {
    font-size: 6.4rem;
    line-height: 6.4rem;
  }

  @include screens-below('xs') {
    font-size: 3.1rem;
    line-height: 3.3rem;
  }
}

.News {
  margin: 0 auto;

  @include screens-above('lg') {
    width: 100%;
  }
}

.RightColumnItems {
  margin-left: 1.6rem;

  @include screens-above('lg') {
    margin: 0 1.6rem;
  }
}

.Feed {
  max-width: 68.4rem;
  padding-bottom: 8rem;
  margin: auto;
}

.ColumnItem {
  margin-bottom: 0.8rem;
}

@include screens-above('lg') {
  .Layout {
    margin: 0 1.6rem;
  }

  .RightColumnItems {
    margin: 0 1.6rem;
  }

  .Header {
    margin-top: -8rem;
    margin-bottom: 4.8rem;
  }

  .HeaderSeason {
    font-size: 13.2rem;
    line-height: 12rem;
  }

  .HeaderMotto {
    font-size: 7rem;
    line-height: 7rem;
  }

  .ColumnItem {
    min-width: 29rem;
    max-width: 29rem;
  }

  .Main {
    display: flex;
    max-width: 87.6rem;
    justify-content: space-between;
    margin: 0 auto;
  }

  .News {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto 5.1rem;
  }
}
