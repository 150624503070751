@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.Footer {
  z-index: z('popover');
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 2.4rem;
  border-top: 1px solid var(--grey-200);
  background-color: var(--white);
  gap: 1rem;

  @include screens-above('md') {
    height: 8rem;
  }

  @include screens-below('md') {
    z-index: z('modal', 'lvl-2');
    flex-direction: column;
    padding: 0.8rem;
  }
}

.FooterRow {
  @include screens-below('md') {
    flex-direction: row;
  }
}

.Open {
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem;
}

.PageMenu {
  // Menu width + component required padding
  padding-left: calc(25.5rem + 2.4rem);

  @include screens-below('xxl') {
    padding-left: calc(9.5rem + 2.4rem);
  }

  @include screens-below('lg') {
    // No menu, just component padding
    padding-left: 2.4rem;
  }

  @include screens-below('md') {
    padding-left: 0.8rem;
  }
}

.Fixed {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
}

.LeftComponent {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.RightComponent {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0;
  gap: 0.8rem;
}

.ErrorMessage {
  margin: 0;
  color: var(--red-80);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}

.BackDrop {
  position: fixed;
  z-index: z('modal', 'lvl-1');
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--black);
  opacity: 0.8;
}

.ActionsWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.AdditionalInfo {
  justify-content: space-between;
  padding-right: 1.2rem;
}

.Popover {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.6rem 0.8rem 2.4rem;
  gap: 3.6rem;
}
