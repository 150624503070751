.Link {
  color: var(--red-60);
  cursor: pointer;
  font-family: var(--font-family-source-sans-pro);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.428rem;

  &:hover:not(:disabled) {
    color: var(--red-80);
  }
}

.LinkBlock {
  display: block;
}
