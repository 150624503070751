@import 'src/styles/mediaQueries';

$card-height: 22.5rem;
$card-max-height: 31.6rem;
$card-width: 46.8rem;

.BountyContainer {
  position: relative;
  display: flex;
  width: $card-width;
  height: 100%;
  min-height: $card-height;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  margin: auto;

  &.Contained {
    width: 100%;
  }

  &.AutoLayout {
    width: $card-width;
  }

  .BountyHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Image {
      height: 7rem;
    }

    .BadgesContainer {
      align-items: flex-start;
    }
  }

  .BountyBody {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
  }

  .BadgesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;
  }

  // se below md: tirar esse margin abaixo

  .InfoContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-grow: row wrap;
    justify-content: flex-start;

    @include screens-above-exclude('md') {
      margin: 0 0 0 2rem;
    }

    > .Header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .BountyTitle {
      @include screens-above-exclude('md') {
        margin-top: 0;
        margin-right: 2rem;
      }
    }
  }

  .Image {
    position: relative;
    display: block;
    height: 10.55rem;
    object-fit: contain;
  }

  .Claimed {
    filter: grayscale(100%);
    opacity: 0.5;
  }

  .SlackChannel {
    margin-top: auto;
    color: var(--grey-400);

    & .RedTag {
      color: var(--red-60);
    }

    & .GreyTag {
      color: var(--grey-500);
    }
  }

  .BountyFooterInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    p {
      font-size: 1.5rem;
    }
  }
}

.WeeklyBountyBorder {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(227.15deg, #4c0db4 1.96%, #6a27d8 50.25%, #96d6f1 98.49%) 1;
}

.FlashBountyBorder {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(227.15deg, #ff5964 1.96%, #ff5964 50.25%, #fad866 98.49%) 1;
}

.DesktopBadges {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.4rem;
}

.Unclaimed {
  margin-left: 1rem;
}

.LockContainer {
  display: flex;
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: var(--color-light-gray);
  cursor: pointer;

  > svg path {
    fill: var(--grey-400);
  }
}
