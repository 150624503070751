.PresetDateRangePicker_panel {
  padding: 0 1.9rem 0.9rem;
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 0.2rem solid var(--color-brand-main);
  color: var(--color-brand-main);
  padding: 0.3rem 1rem;
  margin-right: 0.7rem;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.PresetDateRangePicker_button:active {
  outline: 0;
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: var(--color-brand-main);
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
}
.SingleDatePicker__block {
  display: block;
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: var(--color-bg-2);
  position: absolute;
}
.SingleDatePicker_picker__rtl {
  direction: rtl;
}
.SingleDatePicker_picker__directionLeft {
  left: 0;
}
.SingleDatePicker_picker__directionRight {
  right: 0;
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: var(--color-bg-2);
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.2rem;
  z-index: 2;
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}
.SingleDatePicker_closeButton_svg {
  height: 1.2rem;
  width: 1.2rem;
  fill: #cacccd;
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: var(--color-bg-2);
}
.SingleDatePickerInput__withBorder {
  border: 1px solid #dbdbdb;
}
.SingleDatePickerInput__rtl {
  direction: rtl;
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}
.SingleDatePickerInput__block {
  display: block;
}
.SingleDatePickerInput__showClearDate {
  padding-right: 2.5rem;
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 0.8rem;
  margin: 0 0.8rem 0 0.4rem;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background-color: var(--color-bg-2);
  border-radius: 50%;
}
.SingleDatePickerInput_clearDate__small {
  padding: 0.5rem;
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 1rem;
  width: 1.2rem;
  vertical-align: middle;
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 0.8rem;
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 0.8rem;
  margin: 0 0.4rem 0 0.8rem;
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 1.2rem;
  width: 1.2rem;
  vertical-align: middle;
}
.DateRangePicker {
  position: relative;
  display: inline-block;
}
.DateRangePickerError > .DateRangePicker {
  border: 2px solid var(--color-brand-main);
}
.DateRangePicker__block {
  display: block;
}
.DateRangePicker_picker {
  z-index: 9999;
  background-color: var(--color-bg-2);
  position: absolute;
}
.DateRangePicker_picker__rtl {
  direction: rtl;
}
.DateRangePicker_picker__directionLeft {
  left: 0;
}
.DateRangePicker_picker__directionRight {
  right: 0;
}
.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: var(--color-bg-2);
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.2rem;
  z-index: 2;
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}
.DateRangePicker_closeButton_svg {
  height: 1.2rem;
  width: 1.2rem;
  fill: #cacccd;
}
.DayPicker {
  background: var(--color-bg-2);
  position: relative;
  text-align: left;
}
.DayPicker__horizontal {
  background: var(--color-bg-2);
}
.DayPicker__verticalScrollable {
  height: 100%;
}
.DayPicker__hidden {
  visibility: hidden;
}
.DayPicker__withBorder {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 0.3rem;
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}
.DayPicker_portal__vertical {
  position: initial;
}
.DayPicker_focusRegion {
  outline: 0;
}
.DayPicker_weekHeaders {
  position: relative;
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 0.8rem;
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 4.3rem;
  z-index: 2;
  padding: 0 1.1rem;
  text-align: left;
}
.DayPicker_weekHeader__vertical {
  left: 50%;
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: var(--color-bg-2);
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 1.2rem;
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 0.3rem;
}
.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}
.DayPicker_transitionContainer__vertical {
  width: 100%;
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 1.7rem;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 1.2rem;
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_show {
  width: 1.9rem;
  position: absolute;
  z-index: 2;
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  border-top: 2.2rem solid transparent;
  border-right: 2.8rem solid var(--color-brand-main);
  bottom: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 2.8rem solid var(--color-brand-main);
}
.DayPickerKeyboardShortcuts_show__topRight {
  border-bottom: 2.2rem solid transparent;
  border-right: 2.8rem solid var(--color-brand-main);
  top: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__topRight:hover {
  border-right: 2.8rem solid var(--color-brand-main);
}
.DayPickerKeyboardShortcuts_show__topLeft {
  border-bottom: 2.2rem solid transparent;
  border-left: 2.8rem solid var(--color-brand-main);
  top: 0;
  left: 0;
}
.DayPickerKeyboardShortcuts_show__topLeft:hover {
  border-left: 2.8rem solid #008489;
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute;
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: -2.4rem;
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: -2.4rem;
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: -2.4rem;
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: var(--color-bg-2);
  border: 1px solid #dbdbdb;
  border-radius: 0.2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 1.9rem;
  margin: 2.8rem;
}
.DayPickerKeyboardShortcuts_title {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 1.2rem;
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 1.9rem;
  top: 1.9rem;
  z-index: 2;
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 1.2rem;
  width: 1.2rem;
  fill: #cacccd;
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}
.KeyboardShortcutRow {
  list-style: none;
  margin: 0.5rem 0;
}
.KeyboardShortcutRow__block {
  margin-bottom: 1.4rem;
}
.KeyboardShortcutRow_keyContainer {
  display: inline-block;
  white-space: nowrap;
  text-align: right;
  margin-right: 0.5rem;
}
.KeyboardShortcutRow_keyContainer__block {
  text-align: left;
  display: inline;
}
.KeyboardShortcutRow_key {
  font-family: monospace;
  font-size: 1rem;
  text-transform: uppercase;
  background: var(--color-bg-2);
  padding: 0.2rem 0.5rem;
}
.KeyboardShortcutRow_action {
  display: inline;
  word-break: break-word;
  margin-left: 0.7rem;
}
.DayPickerNavigation {
  position: relative;
  z-index: 2;
}
.DayPickerNavigation__vertical {
  background: var(--color-bg-2);
  box-shadow: 0 0 0.4rem 0.2rem rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4.4rem;
  width: 100%;
}
.DayPickerNavigation__verticalScrollable {
  position: relative;
}
.DayPickerNavigation_button {
  cursor: pointer;
  line-height: 0.78;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: var(--color-bg-2);
  color: #757575;
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4;
}
.DayPickerNavigation_button__default:active {
  background: var(--color-bg-2);
}
.DayPickerNavigation_button__horizontalDefault {
  border-radius: 0.3rem;
  padding: 0.5rem 0.8rem;
  top: 1.5rem;
  position: absolute;
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 1.9rem;
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 1.9rem;
}
.DayPickerNavigation_button__vertical {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50%;
}
.DayPickerNavigation_button__vertical__default {
  padding: 0.4rem;
}
.DayPickerNavigation_nextButton__vertical__default {
  border-left: 0;
}
.DayPickerNavigation_nextButton__verticalScrollable {
  width: 100%;
}
.DayPickerNavigation_svg__horizontal {
  height: 1.6rem;
  width: 1.6rem;
  fill: #82888a;
}
.DayPickerNavigation_svg__vertical {
  height: 3.6rem;
  width: 3.6rem;
  fill: #565a5c;
}
.CalendarMonthGrid {
  background: var(--color-bg-2);
  text-align: left;
  z-index: 0;
}
.CalendarMonthGrid__animating {
  z-index: 1;
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 0.8rem;
}
.CalendarMonthGrid__vertical {
  margin: 0 auto;
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.CalendarMonth {
  background: var(--color-bg-2);
  text-align: center;
  padding: 0 1.1rem;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}
.CalendarMonth_table tbody > tr {
  height: auto;
}
.CalendarMonth_table tr > td,
.CalendarMonth_table tr > td:first-child,
.CalendarMonth_table tr > td:last-child {
  padding: 0;
}
.CalendarMonth_table tr > td:last-child {
  width: auto;
}
.CalendarMonth_caption {
  font: 900 1.4rem 'Open Sans', sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #565a5c;
  padding-top: 2.1rem;
  padding-bottom: 3.1rem;
  caption-side: initial;
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 1rem;
  padding-bottom: 0.6rem;
}
.CalendarDay_container {
  border: 1px solid #e4e7e7;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #565a5c;
  background: var(--color-bg-2);
}
.CalendarDay_container:hover {
  background: var(--color-brand-border);
  border-color: var(--color-brand-border);
  color: inherit;
}
.CalendarDay_button {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  background: 0 0;
  border: 0;
  margin: 0;
  padding: 0;
  color: inherit;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  font-style: inherit;
  font-size: 1.2rem;
}
.CalendarDay_button:active {
  outline: 0;
}
.CalendarDay_button__default {
  cursor: default;
}
.CalendarDay__outside {
  border: 0;
  background: var(--color-bg-2);
  color: #565a5c;
}
.CalendarDay__blocked_minimum_nights {
  background: var(--color-bg-2);
  border: 1px solid #eceeee;
  color: #cacccd;
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: var(--color-bg-2);
  color: #cacccd;
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #565a5c;
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #565a5c;
}
.CalendarDay__selected_span {
  background: var(--color-brand-border);
  border-color: var(--color-brand-border);
  border-top-style: double;
  border-bottom-style: double;
  border-top-color: var(--color-brand-border);
  border-bottom-color: var(--color-brand-border);
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
}
.CalendarDay__last_in_range {
  border-right-color: var(--color-brand-main);
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--color-brand-main);
  border: 1px double var(--color-brand-main);
  color: #fff;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: var(--color-brand-border);
}
.CalendarDay__hovered_span:active {
  background: var(--color-brand-main);
  border: 1px double var(--color-brand-main);
  color: var(--color-text-main);
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: var(--color-bg-2);
  border: 1px solid var(--color-border);
  color: #cacccd;
}
.DateRangePickerInput {
  background-color: var(--color-bg-2) !important;
  display: inline-block;
  margin-left: 1px;
}
.DateRangePickerInput__disabled {
  background: var(--color-bg-2);
  opacity: 0.6;
}
.DateRangePickerInput__withBorder {
  border: 1px solid var(--color-border) !important;
}
.DateRangePickerInput__rtl {
  direction: rtl;
}
.DateRangePickerInput__block {
  display: block;
}
.DateRangePickerInput__showClearDates {
  padding-right: 2.5rem;
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
}
.DateRangePickerInput_arrow::after {
  display: block;
  content: '-';
}
.DateRangePickerInput_arrow_svg {
  display: none;
  vertical-align: middle;
  fill: #565a5c;
  height: 2rem;
  width: 2rem;
}
.DateRangePickerInput_arrow_svg__small {
  display: none;
  height: 1.6rem;
  width: 1.6rem;
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 0.8rem;
  margin: 0 0.8rem 0 0.4rem;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.DateRangePickerInput_clearDates__small {
  padding: 0.5rem;
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 1rem;
  width: 1.2rem;
  vertical-align: middle;
}
.DateRangePickerInput_clearDates_svg__small {
  height: 0.8rem;
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 0.8rem;
  margin: 0 0.4rem 0 0.8rem;
}
.DateRangePickerInput_calendarIcon_svg {
  fill: var(--color-brand-main) !important;
  height: 1.2rem;
  width: 1.2rem;
  vertical-align: baseline;
}
.DateInput {
  margin: 0;
  padding: 0;
  background: var(--color-bg-2);
  position: relative;
  display: inline-block;
  width: 11.1rem;
  vertical-align: middle;
}
.DateInput__small {
  width: 7.7rem;
}
.DateInput__disabled {
  background: var(--color-bg-2);
  color: #dbdbdb;
}
.DateInput_input {
  font: 400 1.6rem 'Source Sans Pro', sans-serif !important;
  color: var(--color-text-main) !important;
  background-color: var(--color-bg-2);
  width: 100%;
  padding: 1.1rem 1rem 0.9rem;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0.2rem solid transparent;
  border-left: 0;
}
.DateInput_input__small {
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding: 0.7rem 0.7rem 0.5rem;
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DateInput_input__focused {
  outline: 0;
  background: var(--color-bg-2);
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0.2rem solid var(--color-brand-main);
  border-left: 0;
}
.DateInput_input__disabled {
  background: var(--color-bg-2);
  font-style: italic !important;
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateInput_fang {
  position: absolute;
  width: 1.7rem;
  height: 0.8rem;
  left: 1.9rem;
  z-index: 2;
}
.DateInput_fangShape {
  fill: #fff;
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent;
}

/* Mobile overrides for custom fullscreen ui */
.DateRangePicker_picker__fullScreenPortal .DayPicker_weekHeaders {
  transform: translateY(3.4rem);
  z-index: 1;
}

.DateRangePicker_picker__fullScreenPortal .DayPicker_focusRegion {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.DateRangePicker_picker__fullScreenPortal .DayPickerNavigation.DayPickerNavigation__vertical {
  background: transparent;
  box-shadow: none;
  width: 25.5rem;
  top: 4.3rem;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

.DateRangePicker_picker__fullScreenPortal
  .DayPicker_transitionContainer.DayPicker_transitionContainer__vertical {
  top: 3.4rem;
}

.DateRangePicker_picker__fullScreenPortal .DayPickerNavigation_svg__horizontal {
  padding: 0.3rem;
  width: 3.3rem;
  height: 2.9rem;
  background: var(--color-bg-2);
  border-width: 1px;
  border-radius: 0.3rem;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  position: fixed;
}

.DateRangePicker_picker__fullScreenPortal .DayPickerNavigation_svg__horizontal {
  top: 0.8rem;
}

.DateRangePicker_picker__fullScreenPortal
  .DayPickerNavigation_svg__horizontal.DayPickerNavigation_svg__horizontal__next {
  right: 0;
}

.DateRangePicker_picker__fullScreenPortal
  .DateRangePickerInput_clearDates.DateRangePickerInput_clearDates_default {
  top: 2.5rem;
  z-index: 999999;
  left: 50%;
  position: fixed;
  transform: translateX(-12.7rem) translateY(-50%);
}

.DateRangePicker_picker__fullScreenPortal .CalendarMonthGrid::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 25.5rem;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none;
}

.DateRangePicker_ApplyButton_Container {
  position: fixed;
  bottom: 0;
  padding: 1.7rem;
  background: var(--color-bg-2);
  z-index: 99999;
  text-align: center;
  left: 0;
  right: 0;
  border-top: 1px solid #dfdfdf;
}

.DateRangePicker_ApplyButton_Container > button {
  font: 700 1.2rem / 1.3 'Open Sans', sans-serif;
  width: 100%;
}

.DateRangePicker_ClearButton_Container {
  position: fixed;
  z-index: 99999;
  top: 1rem;
  font-weight: bold;
  color: #f84b6b;
  left: 1.7rem;
}

.DateRangePicker_closeButton {
  display: none;
}

.DateRangePicker_CustomCloseButton {
  position: fixed;
  z-index: 99999;
  top: 0.3rem;
  right: 0.3rem;
  width: 3.4rem;
  padding: 1rem;
}

.DateRangePicker_CustomCloseButton > svg {
  fill: #989d9e;
}
