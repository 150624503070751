@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.Filter {
  @include screens-below('sm') {
    &.MoreClose {
      .Content {
        display: grid;
        column-gap: 1rem;
        grid-template-columns: auto 0.1fr;

        .ContentItems {
          .Children:first-child {
            // hacks
            div {
              min-width: unset; // remove at Input
              margin: 0 !important; // remove at Input
            }
            // TODO: add attribute at labels to be able handler better.
            > div:first-child > div:first-child > div:has(p), // hide Input label
            > div:first-child > p:first-child {
              display: none;
            }
          }

          .Children:not(:first-child) {
            display: none;
          }
        }

        .ButtonFilter {
          min-width: 4rem;
          padding: 1rem 0.1rem;

          & p {
            display: none;
          }

          & svg {
            margin: 0;

            & path {
              fill: var(--grey-500);
            }
          }
        }

        &.OnlyModal {
          grid-template-columns: 1fr;

          .ContentItems {
            display: none;
          }

          .ButtonFilter {
            width: 100%;

            > p {
              display: block;
            }
          }
        }
      }
    }

    &.MoreOpen {
      .Content {
        .ButtonFilter {
          display: none;
        }

        .ButtonShowResults {
          position: fixed;
          bottom: 1rem;
          left: 50%;
          width: calc(100% - 4rem);
          box-sizing: border-box;
          padding: rem;
          text-align: center;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  @include screens-above('sm') {
    .ButtonShowResults {
      display: none;
    }

    .ButtonFilter {
      &.Empty {
        display: none;
      }

      width: 15.4rem;
      margin-top: 3.05rem;

      &:not(:disabled) {
        border-color: var(--red-60);
        color: var(--red-60);

        & svg {
          & path {
            fill: var(--red-60);
          }
        }
      }
    }

    .Content {
      display: grid;
      column-gap: 2.4rem;
      grid-template-columns: auto 0.1fr;
    }

    .ContentItems {
      display: grid;
      column-gap: 2.4rem;

      .Children.Hidden {
        display: none;
      }
    }
  }
}
