@import 'src/styles/mediaQueries';

.CardContainer {
  min-width: 211px;

  p {
    margin-top: 8px;
    margin-bottom: 0;
  }

  @include screens-below('md') {
    min-width: 150px;
  }

  @include screens-below('xs') {
    min-width: 120px;
  }
}

.DisabledCard {
  pointer-events: none;
}

.Selected {
  background-color: var(--red-20);
}

.SelectedParagraph {
  color: var(--red-60);
}

.DisabledParagraph {
  color: var(--grey-400);
}
