@import 'src/styles/mediaQueries';

.LegacyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LegacyGrid {
  display: inline-grid;
  margin: 1.6rem 0;
  grid-gap: 0.8rem 2.4rem;
  grid-template-columns: 1fr;

  @include screens-above('md') {
    margin: 2.4rem 0 4.1rem;
    grid-gap: 2.4rem 2.4rem;
    grid-template-columns: repeat(2, 30.4rem);
  }

  @media screen and (min-width: 1320px) {
    grid-template-columns: repeat(3, 30.4rem);
  }

  @media screen and (min-width: 1760px) {
    grid-template-columns: repeat(4, 30.4rem);
  }
}

.Wrap {
  width: 30.4rem;
  margin-top: 2.4rem;

  .Heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.8rem;
    font-size: 4rem;
    font-weight: 800;
    line-height: 3.6rem;
  }

  @include screens-above('md') {
    width: 63.2rem;
    margin-top: 4rem;

    .Heading {
      justify-content: flex-start;
      font-size: 6.4rem;
      line-height: 6.4rem;
    }
  }

  @include screens-above('lg') {
    margin-top: 0;
  }

  @media screen and (min-width: 1320px) {
    width: 96rem;
  }

  @media screen and (min-width: 1760px) {
    width: 128.8rem;
  }
}
