@import 'src/styles/mediaQueries';

.StepMessage {
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.StepMessage_hidden {
  display: none;
}

.StepMessage_inline {
  margin: -0.8rem auto 1.7rem 0;
}

.StepMessage_title {
  display: none;
  font: 900 1.4rem 'Open Sans', sans-serif;
  text-transform: uppercase;
}

.StepMessage_subTitle {
  display: none;
  font: 400 1.4rem / 1.3 'Open Sans', sans-serif;
}

@include screens-above('md') {
  .StepMessage_inline {
    display: inline-block;
    margin: 2.5rem auto auto 1.7rem;
  }

  .StepMessage_title {
    display: block;
    margin: 3.4rem 0 1.7rem;
  }

  .StepMessage_subTitle {
    display: block;
    margin: 0 0 5.1rem;
  }
}
