@import 'src/styles/mediaQueries';

.Tile {
  width: fit-content;
  padding: 20px 35px;
  border: 1px solid var(--grey-300);

  @include screens-below('lg') {
    padding: 16px;
  }
}

.Active {
  border-color: var(--red-80);
  background-color: var(--red-20);
}
