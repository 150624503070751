@import 'src/styles/mediaQueries';

.ProgressBarStep_Icon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  text-align: center;
}

.ProgressBarStep_Icon_Active {
  background-color: var(--color-brand-main);
  box-shadow: 0 0 0 0.6rem rgb(var(--color-rgb-pink), 0.25);
}

.ProgressBarStep_Icon_Inactive {
  border: var(--color-border) solid 1px;
  background-color: var(--color-white);
}

.ProgressBarStep {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProgressBarStep_Icon_Image {
  display: none;
}

.ProgressBarStep_Title_First {
  left: -0.6rem;
}

.ProgressBarStep_Title_Last {
  right: -0.6rem;
}

@include screens-above('md') {
  .ProgressBarStep_Icon_Image {
    display: inline-block;
    width: 1.8rem;
    height: 100%;
  }

  .ProgressBarStep_Icon {
    width: 4.3rem;
    height: 4.3rem;
  }

  .ProgressBarStep_Icon_Active {
    box-shadow: none;
  }

  .ProgressBarStep_Title_First {
    left: auto;
  }

  .ProgressBarStep_Title_Last {
    right: auto;
  }
}
