.UserMenu {
  width: 28.4rem;
  border: 1px solid var(--grey-300);
  background-color: var(--white);
}

.ItemsList {
  padding: 0;
  margin: 0;
}

.User {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 1.6rem 1.6rem;
  cursor: pointer;
  gap: 1.6rem;
}

.UserInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.4rem;

  & p {
    margin: 0;
  }
}

.Divider {
  margin: 0;
}

.Stash {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;

  & p {
    margin: 0;
  }

  & svg {
    width: 2rem;
    height: 2rem;
  }
}

.StashContainer {
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0;
  gap: 1.6rem;
}
