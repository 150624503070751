@import 'src/styles/mediaQueries';

.EditStep {
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--color-brand-main);
  cursor: pointer;
  font: 1.4rem/1 'Source Sans Pro', sans-serif;

  &:focus {
    outline: none;
  }
}

.EditStep_profile {
  margin-left: 1.7rem;
}

.EditStep_submit {
  margin-left: auto;
}

.EditStep_img {
  width: 1.2rem;
}

.EditStep_img_inactive {
  opacity: 0.5;
}

.EditStep_title {
  display: none;
}

@include screens-above('md') {
  .EditStep_title {
    display: block;
  }

  .EditStep_img {
    display: none;
  }

  .EditStep_submit {
    margin-right: 1.7rem;
  }
}
