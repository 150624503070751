@import 'src/styles/mediaQueries';

.Container {
  margin: 5.1rem auto;
}

.SubmitSteps {
  padding: 0 1.7rem;
  margin: 0;
  list-style: none;
}

@include screens-above('md') {
  .Container {
    max-width: 73.1rem;
    margin: 11.1rem auto;
  }

  .SubmitSteps {
    padding: 0;
  }
}
