:root {
  --font-size: 14px;
}

.CommonInputLayout {
  width: 297px;
  min-width: 297px;
  height: 40px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--grey-300);
  appearance: none;
  background: var(--white);
  cursor: text;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.25rem;
}

.labelHelper {
  margin: 0;
  color: var(--grey-500);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.25rem;
}

.label {
  margin-right: 4px;
  color: var(--grey-400) !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1.25rem;
}

.label-required {
  display: inline-flex;
  margin: 0 0 0 0.4rem;
  color: var(--red-60);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.25rem;
  vertical-align: bottom;
}

.labelWrapper {
  display: inline-flex;
  padding: 0;
  vertical-align: bottom;
}

.input {
  composes: CommonInputLayout;
  padding: 8px;

  &.withLeftComponent {
    padding-left: 42px;
  }

  &.withRightComponent {
    padding-right: 42px;
  }
}

.mainContainer {
  position: relative;
  width: 100%;
  margin: 8px 0;
}

.mainContainerNoMargin {
  position: relative;
  width: 100%;
}

.selectableChevron {
  svg {
    position: absolute;
    right: 0;
    box-sizing: content-box;
    padding-left: 8px;
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;

    & path {
      fill: var(--grey-400);
    }
  }
}

.leftComponent,
.rightComponent {
  position: absolute;
  display: flex;
  height: 100%;
  align-items: center;
}

.leftComponent {
  left: 0;
}

.rightComponent {
  right: 0;
}

.state--inactive {
  ::placeholder {
    color: var(--grey-400);
  }
}

.state--focused {
  ::placeholder {
    color: var(--grey-400);
  }

  .CommonInputLayout {
    border: 1px solid var(--black);
  }
}

.state--disabled {
  .CommonInputLayout {
    border: 1px solid var(--grey-300);
    background: var(--grey-100);
  }

  ::placeholder {
    color: var(--grey-300);
  }

  .label {
    color: var(--grey-300);
  }

  & svg {
    & path {
      fill: var(--grey-300);
    }
  }
}

.state--filled {
  ::placeholder {
    color: var(--black-800);
  }
}

.state--error {
  .CommonInputLayout {
    border: 1px solid var(--red-60);
  }

  ::placeholder {
    color: var(--black-800);
  }

  .label {
    color: var(--red-60);
  }
}

.state--success {
  .CommonInputLayout {
    border: 1px solid var(--utility-green-100);
  }

  ::placeholder {
    color: var(--black-800);
  }

  .label {
    color: var(--utility-green-100);
  }
}

.layout--autolayout {
  display: inline-flex;
  width: auto;

  .CommonInputLayout {
    width: auto;
  }
}

.layout--fluid {
  display: flex;
  width: 100%;

  .CommonInputLayout {
    width: 100%;
    min-width: unset;
  }

  &.inputResult {
    width: 100%;
    min-width: unset;
  }
}

.inputResult {
  position: relative;
  z-index: 9000;
  display: table;
  min-width: 297px;
  background: var(--white);
}

.CommonInputResetStyle {
  margin: 0;

  .mainContainer {
    display: flex;
    margin: 0;
  }

  .input {
    width: 100%;
  }
}
