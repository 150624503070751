@import 'src/styles/mediaQueries';

.H1,
.H1Prompt {
  margin: 0;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 13.2rem;
  font-style: italic;
  font-weight: 800;
  line-height: 12rem;
  text-transform: uppercase;
}

.H2,
.H2Prompt {
  margin: 0;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 6.4rem;
  font-style: italic;
  font-weight: 800;
  line-height: 6.4rem;
  text-transform: uppercase;
}

.H3,
.H3Prompt {
  margin: 0;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 3.4rem;
  font-style: italic;
  font-weight: 800;
  line-height: 4.4rem;
  text-transform: uppercase;
}

.H4,
.H4Prompt {
  margin: 0;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: bold;
  line-height: 3.2rem;

  &.H4Prompt {
    color: var(--color-text-title);
  }
}

.H5,
.H5Prompt {
  margin: 0;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 2.5rem;
}

.H6,
.H6Prompt {
  margin: 0;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: bold;
  line-height: 2.2rem;
}

@include screens-below('lg') {
  .H2 {
    font-size: 4rem;
    line-height: 3.6rem;
    text-align: center;
  }
}
