@import 'src/styles/mediaQueries';

.UnleashRemainingSubHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 2.4rem;
  border: 1px solid var(--grey-200);
  background-color: var(--white);

  @include screens-below('md') {
    padding: 1.6rem;
  }
}

.UnleashInvoiceItems {
  display: flex;
  overflow: hidden;
  max-height: 5rem;
  flex-wrap: wrap;
}

.UnleashRemainingItem {
  padding-right: 1.6rem;
}

.UnleashRemainingValue {
  margin: 0;
  color: var(--red-60);
  font-size: 1.4rem;
  font-weight: 700;
}

.UnleashInvoiceItem {
  padding: 0 1.6rem;
  border-left: 1px solid var(--grey-300);

  &:last-child {
    padding: 0 0 0 1.6rem;
  }

  @include screens-below('md') {
    display: none;
  }
}

.UnleashInvoiceCategory {
  padding-bottom: 0.8rem;
  margin: 0;
  color: var(--grey-400);
  font-size: 1.4rem;
  font-weight: 700;
}

.UnleashInvoiceValue {
  margin: 0;
  color: var(--grey-800);
  font-size: 1.4rem;
  font-weight: 700;
}

// Unleash Remaining Modal Styles

.UnleashInvoiceHeadingModal {
  margin: 0;

  @include screens-below('md') {
    display: none;
  }
}

.UnleashInvoiceItemsModal {
  display: flex;
  flex-wrap: wrap;
  padding: 2.4rem;

  @include screens-below('md') {
    padding: 1.6rem;
  }
}

.UnleashRemainingItemModal {
  width: 100%;
  padding: 2.4rem 0 1.6rem 0;

  @include screens-below('md') {
    padding: 0 0 1.6rem 0;
  }
}

.UnleashInvoiceItemModal {
  width: 100%;
  padding: 1.6rem 0;
  border-top: 1px solid var(--grey-300);
  border-left: none;

  &:last-child {
    padding: 1.6rem 0;
  }

  @include screens-below('md') {
    display: initial;
  }
}

.UnleashRemainingModalContent {
  max-height: 82rem;
}
