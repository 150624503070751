@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.Overlay {
  width: 100%;
  height: 100%;

  @include screens-above('md') {
    position: fixed;
    z-index: z('modal', 'lvl-2');
    top: 0;
    left: 0;
    display: flex;
    overflow: scroll;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--rgb-black), 0.8);
  }
}

.CloseButton {
  position: absolute;
  top: 2.2rem;
  right: 2.8rem;
  display: none;
  width: 4rem;
  height: 4rem;

  @include screens-above('md') {
    display: flex;
  }
}

.Wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  padding: 1.6rem;
  background-color: var(--white);

  @include screens-above('md') {
    width: 46.8rem;
    height: initial;
    padding: 4rem;
  }
}

.CommonText {
  display: -webkit-box;
  margin: 0;
  margin-bottom: 1.3rem;

  @include screens-above('md') {
    margin-bottom: 1.6rem;
  }
}

.Title {
  composes: CommonText;
  color: var(--grey-800);
}

.SubTitle {
  composes: CommonText;
  color: var(--color-text-title);
}

.Warning {
  composes: CommonText;
  color: var(--red-60);

  @include screens-above('md') {
    margin-bottom: 4rem;
  }
}

.ButtonContainer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  row-gap: 0.8rem;

  @include screens-above('md') {
    flex-direction: row;
    padding-left: 5.6rem;
    column-gap: 0.8rem;

    button:first-child {
      width: 76%;
    }
  }
}
